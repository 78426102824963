import { Injectable, inject } from '@angular/core';
import { Location, LocationService } from '../planvue-api';
import { SelectedItemService } from './selected-item.service';
@Injectable({
  providedIn: 'root'
})
export class SelectedLocationService extends SelectedItemService<Location, LocationService>{
  protected override service: LocationService = inject(LocationService);
  private id?: number;
  load(id: number) {
    this.id = id;
    this.service.getLocation(id).subscribe((location) => {
      this.setItem(location);
    });
  }
}

