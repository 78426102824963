import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, debounceTime, filter, startWith } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { FilteredPagedToolbarComponentWithSelectedVenueComponent } from 'src/app/components/base-classes/filtered-paged-toolbar-component';
import { VenueLocationTableComponent } from 'src/app/components/location-table/venue-location-table.component';
import { LocationFilter, VenueLocationsDataSource } from 'src/app/datasources/venue-locations-datasource';
import { ToolbarAction } from 'src/app/models/toolbar-action';
import { Location, User } from 'src/app/planvue-api';
import { NewLocationDialogComponent } from '../dialog/location-dialog.component';

@Component({
  selector: 'app-venue-location',
  templateUrl: './location-list.component.html',
  styleUrl: './location-list.component.scss',
  standalone: true,
  imports: [...commonModules, VenueLocationTableComponent]
})
export class LocationListComponent extends FilteredPagedToolbarComponentWithSelectedVenueComponent<Location, LocationFilter, VenueLocationsDataSource> implements OnInit, OnDestroy {
  private readonly formBuidler = inject(FormBuilder);
  private readonly dialog = inject(MatDialog);

  private user!: User;
  filterForm: FormGroup = this.formBuidler.group({
    searchTerm: [],
    linked: ['both']
  });
  datasource: VenueLocationsDataSource = new VenueLocationsDataSource();
  searchTerm$ = this.filterForm.get('searchTerm')!.valueChanges.pipe(startWith(''), debounceTime(250));
  linkStatus$ = this.filterForm.get('linked')!.valueChanges.pipe(startWith('both'), debounceTime(250));

  constructor() {
    super();
  }

  resetFilter(): void {
    this.filterForm.setValue({ searchTerm: '', linked: 'both' });
    this.filterForm.markAsPristine();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.datasource.venue = this.venue;
    this.user = this.route.snapshot.data['currentUser'] as User;
    if (this.user.role !== 'admin') { 
      this.datasource.columns = this.datasource.columns.filter((column) => column !== 'delete');
    }

    this.addToolbarActionToFront({
      id: 'add-location',
      icon: 'add',
      title: 'Add Location',
      addDivider: true,
      description: 'Add a new location',
      requiredRoles: ['admin'],
    });
    combineLatest([this.searchTerm$, this.linkStatus$]).subscribe(([searchTerm, linked]) => {
      this.filterValue$.next({ searchTerm: searchTerm, linked: linked });
    });
  }

  ngOnDestroy(): void {
    this.removeToolbarAction('add-location');
  }

  override toolbarActionClicked(action: ToolbarAction) {
    if (action.id === 'add-location') {
      const dialogRef = this.dialog.open(NewLocationDialogComponent, {
        minWidth: "600px",
        autoFocus: 'first-tabbable',
        data: { venue: this.venue }
      });
      dialogRef.afterClosed()
        .pipe(filter((location) => location))
        .subscribe(() => {
            this.datasource.loadItems();
        });
    }
  }


  locationSelected(location: Location) {
    this.router.navigate(['/venue', this.datasource.venue?.id, 'location', location.id]);
  }

  viewLocationOnMapSelected(location: Location) {
    this.router.navigate(['/venue', this.datasource.venue?.id, 'map'], { queryParams: { 'location_id': location.id } });
  }
}
