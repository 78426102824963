import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/planvue-api';
import { ProfileService } from '../services/profile.service';

/**
 * This is a convenience resolver that injects the current user from the profile service into the page.
 * 
 * @param route 
 * @param state 
 * @param profileService 
 * @returns 
 */
export const currentUserResolver: ResolveFn<Observable<User>> = (
  route, 
  state, 
  profileService = inject(ProfileService)
):Observable<User> => {
    return profileService.user$;
};
