import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, filter, map } from "rxjs";

export class ErrorInterceptor implements HttpInterceptor {
  router = inject(Router);
  constructor() {
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    return next.handle(req).pipe(
      filter((event) => event.type === HttpEventType.Response),
      map((event) => {
        if (event instanceof HttpResponse) {
          if (event.status >= 500) { 
            this.router.navigate(['/error']);
          }
        }
        return event;
      }));
  }
}
