<app-toolbar></app-toolbar>
<div class="container">
    <app-user-form [formGroup]="userForm" [venues]="venues" [permitVenueAssignment]="permitVenueAssignment" [showAssignedVenues]="showAssignedVenues">
    </app-user-form>
    <div class="buttons">
        <button mat-raised-button (click)="reset()">Reset</button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!userForm.valid || !userForm.dirty">{{ buttonLabel }}</button>
        @if (mode === 'edit') {
            <button mat-raised-button color="warn" (click)="delete()"> Delete </button>
            <button mat-raised-button color="primary" (click)="send_reset_email()">Send Password Reset Email</button>
        }
    </div>
</div>
