// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sentry_dsn: "https://7ab878fb1c6c9b82fbaead34ac4e6bd7@o4506411735121920.ingest.us.sentry.io/4507118537342976",
  sentry_environment: 'prod',
  sha: 'f0ce89752a85',
  ref: 'v1.0.16',
  apiBaseUrl: 'https://api.prod.planvue.11thhourbiz.com',
  auth: {
    domain: 'planvue-prod.us.auth0.com',
      clientId: 'PyVWf6Sh3ksiasWeCVLlZ6o4FevyF1n9',
      authorizationParams: {
        audience: "https://planvue.11thhourbiz.com",
        redirect_uri: window.location.origin
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://api.prod.planvue.11thhourbiz.com/healthcheck',
          allowAnonymous: true,
        },
        {
          uri: 'https://api.prod.planvue.11thhourbiz.com/*',
          tokenOptions: {
            authorizationParams: {
              // The attached token should target this audience
              audience: 'https://planvue.11thhourbiz.com',
              scope: 'openid email profile'
            }
          }
        }
      ]
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
