import { Component, Input } from '@angular/core';
import { BaseComponent } from './base-component';
import { PagedItemsDatasource } from './paged-items-datasource';

@Component({
    template: ''
  })
  export abstract class PagedTableComponent<I, Y extends PagedItemsDatasource<I>> extends BaseComponent{
    @Input({ required: true })
    datasource!: Y;
  
    @Input()
    columns: string[] = [];
  }