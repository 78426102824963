import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { commonModules } from "src/app/app.config";
import { BaseFormComponent } from "src/app/components/base-classes/base-form-component";
import { Venue } from "src/app/planvue-api";

export interface DisplayedVenue {
  name: string;
  id: number;
  selected: boolean;
}

export interface UserForm { 
  first_name: FormControl<string | null>;
  last_name: FormControl<string | null>;
  email: FormControl<string | null>;
  organization: FormControl<string | null>;
  role: FormControl<'admin' | 'user' |'inhouse' | null>;
  active: FormControl<boolean | null>;
  venues: FormControl<number[] | null>;
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.scss',
  standalone: true, 
  imports: [...commonModules],
})
export class UserFormComponent extends BaseFormComponent<UserForm> implements OnChanges {
  @Input()
  venues? : DisplayedVenue[] = [];

  @Input()
  permitVenueAssignment = false;

  @Input()
  showAssignedVenues = false; 

  @Input()
  assignedVenues: Venue[] = []

  roles = [{ 
    label: "Admin",
    value: "admin"
  }, {
    label: "In House",
    value: "inhouse"
  }, {
    label: "User",
    value: "user"
  }]


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['venues'] && changes['venues'].currentValue) {
      this.assignedVenues = this.venues!.filter(v => v.selected).map(v => {
        return {
          id: v.id,
          name: v.name
        }
      })
    }
  }
}
