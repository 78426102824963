import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { PagedItemsDatasource } from "src/app/components/base-classes/paged-items-datasource";
import { Page_Template_, Template, Venue, VenueService } from "src/app/planvue-api";

export class VenueTemplatesDataSource extends PagedItemsDatasource<Template> {
    private venueService: VenueService = inject(VenueService);
    public venue!: Venue;
    public columns: string[] = ["title", "filename", "format", "delete"];
    
    constructor() {
        super();
    }

    override requestItems(pageIndex:number, pageSize: number): Observable<Page_Template_> {
        return this.venueService.getVenueTemplates(this.venue.id!, pageIndex + 1, pageSize);
    }
}