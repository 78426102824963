export const $FavoriteLocation = {
	properties: {
		id: {
	type: 'number',
	isRequired: true,
},
		name: {
	type: 'string',
	isRequired: true,
},
		venue_id: {
	type: 'number',
	isRequired: true,
},
	},
} as const;