<app-toolbar/>
<section class="filters">
    <form [formGroup]="filterForm">
        <div class="inner">
        <mat-form-field subscriptSizing="dynamic" >
            <mat-label>Search Favorites</mat-label>
            <input matInput type="text" formControlName="searchTerm">
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="resetFilter()" [disabled]="!filterForm.dirty">Reset</button>
        <button type="submit" hidden="true"></button>
        </div>
    </form>
</section>
<app-favorite-table [datasource]="datasource"/>
<mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
<mat-spinner *ngIf="datasource.loading$ | async"></mat-spinner>


