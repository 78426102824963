export const $VenueMap = {
	properties: {
		venue_id: {
	type: 'number',
	isRequired: true,
},
		map_url: {
	type: 'string',
	isRequired: true,
},
		map_height: {
	type: 'number',
	isRequired: true,
},
		map_width: {
	type: 'number',
	isRequired: true,
},
		areas: {
	type: 'array',
	contains: {
		type: 'Area',
	},
	isRequired: true,
},
	},
} as const;