export const $FavoriteLocationAdd = {
	properties: {
		favorite_id: {
	type: 'number',
	isRequired: true,
},
		location_id: {
	type: 'number',
	isRequired: true,
},
	},
} as const;