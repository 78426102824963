export const $FavoriteLocationAddMany = {
	properties: {
		favorite_ids: {
	type: 'array',
	contains: {
	type: 'number',
},
	isRequired: true,
},
		location_id: {
	type: 'number',
	isRequired: true,
},
	},
} as const;