import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Body_add_location_image } from '../models/Body_add_location_image';
import type { Location } from '../models/Location';
import type { LocationAdd } from '../models/LocationAdd';
import type { LocationImage } from '../models/LocationImage';
import type { LocationUpdate } from '../models/LocationUpdate';
import type { Page_Location_ } from '../models/Page_Location_';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class LocationService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Get Locations
	 * @param page 
	 * @param pageSize 
	 * @returns Page_Location_ Successful Response
	 * @throws ApiError
	 */
	public getLocations(
page: number = 1,
pageSize: number = 25,
): Observable<Page_Location_> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/location',
			query: {
				page, page_size: pageSize
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Location
	 * @param venueId 
	 * @param requestBody 
	 * @returns Location Successful Response
	 * @throws ApiError
	 */
	public addLocation(
venueId: number,
requestBody: LocationAdd,
): Observable<Location> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/location',
			query: {
				venue_id: venueId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Location
	 * @param locationId 
	 * @returns Location Successful Response
	 * @throws ApiError
	 */
	public getLocation(
locationId: number,
): Observable<Location> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/location/{location_id}',
			path: {
				location_id: locationId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Update Location
	 * @param locationId 
	 * @param requestBody 
	 * @returns Location Successful Response
	 * @throws ApiError
	 */
	public updateLocation(
locationId: number,
requestBody: LocationUpdate,
): Observable<Location> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/location/{location_id}',
			path: {
				location_id: locationId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Location Images
	 * @param locationId 
	 * @returns LocationImage Successful Response
	 * @throws ApiError
	 */
	public getLocationImages(
locationId: number,
): Observable<Array<LocationImage>> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/location{location_id}/image',
			path: {
				location_id: locationId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Location Image
	 * @param locationId 
	 * @param formData 
	 * @returns LocationImage Successful Response
	 * @throws ApiError
	 */
	public addLocationImage(
locationId: number,
formData: Body_add_location_image,
): Observable<LocationImage> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/location{location_id}/image',
			path: {
				location_id: locationId
			},
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Location Image
	 * @param locationImageId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteLocationImage(
locationImageId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/location{location_id}/image/{location_image_id}',
			path: {
				location_image_id: locationImageId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Location
	 * @param locationId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteLocation(
locationId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/location{location_id}',
			path: {
				location_id: locationId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}