import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { User } from 'src/app/planvue-api';
import { UserFilter, UsersDataSource } from '../../datasources/users-datasource';
import { PagedTableComponent } from '../base-classes/paged-table';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  standalone: true,
  imports: [...commonModules],
  styleUrl: './user-table.component.scss'
})
export class UserTableComponent extends PagedTableComponent<User, UsersDataSource> implements OnInit, OnDestroy {
  userFilter$: BehaviorSubject<UserFilter> = new BehaviorSubject(new UserFilter('', 'all', 'both'));
  route: ActivatedRoute = inject(ActivatedRoute);
  @Output()
  userSelected: EventEmitter<User> = new EventEmitter<User>();
  
  @Output()
  impersonateUserSelected: EventEmitter<User> = new EventEmitter<User>();
  userSubscription?: Subscription;

  public constructor() {
    super();
  }

   ngOnInit(): void {
    this.columns = this.datasource.columns;
  }

  ngOnDestroy(): void {
    if (this.userSubscription) { 
      this.userSubscription.unsubscribe();
    }
  }

  impersonateUser(user: User): void {
    if (user !== undefined && user.email)
      this.impersonateUserSelected.emit(user);
  }

  getNoDataMessage(): string {
    return 'No users found.';
  }

  selectUser(user: User): void {
    this.userSelected.emit(user);
  }
}
