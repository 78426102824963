import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Area } from '../models/Area';
import type { AreaAdd } from '../models/AreaAdd';
import type { AreaUpdate } from '../models/AreaUpdate';
import type { Body_add_venue_template } from '../models/Body_add_venue_template';
import type { Body_set_map_image } from '../models/Body_set_map_image';
import type { Body_update_venue_photo } from '../models/Body_update_venue_photo';
import type { Page_Location_ } from '../models/Page_Location_';
import type { Page_Template_ } from '../models/Page_Template_';
import type { Page_Venue_ } from '../models/Page_Venue_';
import type { Template } from '../models/Template';
import type { UnlinkedLocation } from '../models/UnlinkedLocation';
import type { Venue } from '../models/Venue';
import type { VenueAdd } from '../models/VenueAdd';
import type { VenueMap } from '../models/VenueMap';
import type { VenueUpdate } from '../models/VenueUpdate';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class VenueService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Get Venues
	 * @param page 
	 * @param pageSize 
	 * @param search 
	 * @returns Page_Venue_ Successful Response
	 * @throws ApiError
	 */
	public getVenues(
page: number = 1,
pageSize: number = 25,
search?: string,
): Observable<Page_Venue_> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/venue',
			query: {
				page, page_size: pageSize, search
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Venue
	 * @param venueId 
	 * @returns Venue Successful Response
	 * @throws ApiError
	 */
	public getVenue(
venueId: number,
): Observable<Venue> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/venue/{venue_id}',
			path: {
				venue_id: venueId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Venue
	 * @param venueId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteVenue(
venueId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/venue/{venue_id}',
			path: {
				venue_id: venueId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Update Venue
	 * @param venueId 
	 * @param requestBody 
	 * @returns Venue Successful Response
	 * @throws ApiError
	 */
	public updateVenue(
venueId: number,
requestBody: VenueUpdate,
): Observable<Venue> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/venue/{venue_id}',
			path: {
				venue_id: venueId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Venue
	 * @param requestBody 
	 * @returns Venue Successful Response
	 * @throws ApiError
	 */
	public addVenue(
requestBody: VenueAdd,
): Observable<Venue> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/venue/',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Update Venue Photo
	 * @param venueId 
	 * @param formData 
	 * @returns Venue Successful Response
	 * @throws ApiError
	 */
	public updateVenuePhoto(
venueId: number,
formData: Body_update_venue_photo,
): Observable<Venue> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/venue/{venue_id}/photo',
			path: {
				venue_id: venueId
			},
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Set Map Image
	 * @param venueId 
	 * @param formData 
	 * @returns Venue Successful Response
	 * @throws ApiError
	 */
	public setMapImage(
venueId: number,
formData: Body_set_map_image,
): Observable<Venue> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/venue/{venue_id}/map',
			path: {
				venue_id: venueId
			},
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Venue Map
	 * @param venueId 
	 * @returns VenueMap Successful Response
	 * @throws ApiError
	 */
	public getVenueMap(
venueId: number,
): Observable<VenueMap> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/venue/{venue_id}/map',
			path: {
				venue_id: venueId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Unlinked Locations
	 * @param venueId 
	 * @returns UnlinkedLocation Successful Response
	 * @throws ApiError
	 */
	public getUnlinkedLocations(
venueId: number,
): Observable<Array<UnlinkedLocation>> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/venue/{venue_id}/unlinked_locations',
			path: {
				venue_id: venueId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Venue Locations
	 * @param venueId 
	 * @param page 
	 * @param pageSize 
	 * @param search 
	 * @param linkstatus 
	 * @returns Page_Location_ Successful Response
	 * @throws ApiError
	 */
	public getVenueLocations(
venueId: number,
page: number = 1,
pageSize: number = 25,
search?: string,
linkstatus?: string,
): Observable<Page_Location_> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/venue/{venue_id}/location',
			path: {
				venue_id: venueId
			},
			query: {
				page, page_size: pageSize, search, linkstatus
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Venue Templates
	 * @param venueId 
	 * @param page 
	 * @param pageSize 
	 * @returns Page_Template_ Successful Response
	 * @throws ApiError
	 */
	public getVenueTemplates(
venueId: number,
page: number = 1,
pageSize: number = 25,
): Observable<Page_Template_> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/venue/{venue_id}/template',
			path: {
				venue_id: venueId
			},
			query: {
				page, page_size: pageSize
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Venue Template
	 * @param venueId 
	 * @param formData 
	 * @returns Template Successful Response
	 * @throws ApiError
	 */
	public addVenueTemplate(
venueId: number,
formData: Body_add_venue_template,
): Observable<Template> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/venue/{venue_id}/template',
			path: {
				venue_id: venueId
			},
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Map Area
	 * @param venueId 
	 * @param requestBody 
	 * @returns Area Successful Response
	 * @throws ApiError
	 */
	public addMapArea(
venueId: number,
requestBody: AreaAdd,
): Observable<Area> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/venue/{venue_id}/area',
			path: {
				venue_id: venueId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Update Map Area
	 * @param venueId 
	 * @param areaId 
	 * @param requestBody 
	 * @returns Area Successful Response
	 * @throws ApiError
	 */
	public updateMapArea(
venueId: number,
areaId: number,
requestBody: AreaUpdate,
): Observable<Area> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/venue/{venue_id}/area/{area_id}',
			path: {
				venue_id: venueId, area_id: areaId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Map Area
	 * @param venueId 
	 * @param areaId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteMapArea(
venueId: number,
areaId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/venue/{venue_id}/area/{area_id}',
			path: {
				venue_id: venueId, area_id: areaId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}