import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { RequiredRolesDirective } from 'src/app/directives/required-roles.directive';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { ToolbarAction } from '../../models/toolbar-action';
import { ToolbarService } from '../../services/toolbar.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  standalone: true,
  imports: [MatDividerModule, MatIconModule, MatButtonModule, MatTooltipModule,  RouterModule, MatToolbarModule, CommonModule, BreadcrumbItemDirective, BreadcrumbComponent,
    RequiredRolesDirective
  ]
})
export class ToolbarComponent {
  toolbarService: ToolbarService = inject(ToolbarService);
  actions$ = this.toolbarService.actions$;
  actions: ToolbarAction[] = [];
  breadcrumbVisible$: Observable<boolean> = this.toolbarService.breadcrumbVisible$;

  constructor() {
    this.actions$.subscribe((actions) => {
     this.actions = actions;
    });
  }

  onClick(action: ToolbarAction) {
    this.toolbarService.actionClicked(action);
  }

}
