<form [formGroup]="formGroup" class="container">
    <div class="details">
        <h2>Details</h2>
        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput type="input" formControlName="first_name" placeholder="First Name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput type="input" formControlName="last_name" placeholder="Last Name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" placeholder="Email Address" />
            <mat-error *ngIf="formGroup.get('email')?.hasError('already_exists')">
                An account with this email address already exists
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Organization</mat-label>
            <input matInput type="input" formControlName="organization" placeholder="Organization" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role">
                <mat-option *ngFor="let role of roles" [value]="role.value">{{ role.label }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-slide-toggle formControlName="active">Active</mat-slide-toggle>
    </div>
    <div class="venues">
        <h2> Venues</h2>
        @if (!permitVenueAssignment) {
        <mat-icon>lock</mat-icon>
        This user has the role of '{{ formGroup.controls['role'].value }}'
        which allows access to all venues.
        } @else {
        @if (permitVenueAssignment) {
        <mat-selection-list [class]="{ 'error': formGroup.hasError('venuesRequired') }" formControlName="venues">
            <mat-list-option *ngFor="let venue of venues" [value]="venue.id">{{venue.name}}</mat-list-option>
        </mat-selection-list>
        <mat-error *ngIf="formGroup.hasError('venuesRequired')">Please select at least one venue</mat-error>
        } @else if (showAssignedVenues) {
        <mat-list>
            <mat-list-item *ngFor="let venue of assignedVenues">{{venue.name}}</mat-list-item>
        </mat-list>
        }
        }
    </div>
</form>