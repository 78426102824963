import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { commonModules } from 'src/app/app.config';
import { Venue, VenueService } from 'src/app/planvue-api';
import { BaseComponent } from '../../base-classes/base-component';
import { UploadComponent, UploadType } from '../../upload/upload.component';

export interface VenueDialogConfig {
  venue: Venue
}

@Component({
  selector: 'app-venue-template-upload-dialog',
  templateUrl: './venue-template-upload-dialog.component.html',
  standalone: true, 
  imports: [...commonModules, UploadComponent],
  styleUrl: './venue-template-upload-dialog.component.scss'
})
export class VenueTemplateUploadDialogComponent extends BaseComponent {
  private readonly venueService: VenueService = inject(VenueService);

  public venue!: Venue;

  readonly uploadFileType: UploadType = UploadType.ZIP;
  
  constructor(public dialog: MatDialogRef<VenueTemplateUploadDialogComponent>,  @Inject(MAT_DIALOG_DATA) public config: VenueDialogConfig) {
    super();
    this.venue = config.venue;
  }

  onFileSelected(file: File): void {
    this.venueService.addVenueTemplate(this.venue.id!,
      { upload: file }
    ).subscribe(() => {
      this.notifySuccess('Template uploaded successfully');
      this.dialog.close();
    });
  }

  onDismiss(): void {
    this.dialog.close(false);
  }

  onConfirm(): void {
    this.dialog.close(true);
  }

}
