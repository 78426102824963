export const $Venue = {
	properties: {
		id: {
	type: 'any-of',
	contains: [{
	type: 'number',
}, {
	type: 'null',
}],
},
		name: {
	type: 'string',
	isRequired: true,
},
		image_url: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		map_url: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		description: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		created_at: {
	type: 'string',
	format: 'date-time',
},
		updated_at: {
	type: 'string',
	format: 'date-time',
},
		updated_by: {
	type: 'any-of',
	contains: [{
	type: 'User',
}, {
	type: 'null',
}],
},
		created_by: {
	type: 'any-of',
	contains: [{
	type: 'User',
}, {
	type: 'null',
}],
},
	},
} as const;