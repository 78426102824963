import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { filter } from 'rxjs';
import { ToolbarPageComponent } from 'src/app/components/base-classes/toolbarpage-component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { VenueTemplateTableComponent } from 'src/app/components/venue-template-table/venue-template-table.component';
import { Venue } from 'src/app/planvue-api';
import { SelectedVenueService } from 'src/app/services/selected-venue-service';
import { VenueTemplateListComponent } from '../../venue-template/venue-template-list.component';

@Component({
  selector: 'app-venue-base',
  templateUrl: './venue-base.component.html',
  standalone: true,
  imports: [RouterModule, ToolbarComponent, MatDividerModule],
  styleUrl: './venue-base.component.scss'
})
export class VenueBaseComponent extends ToolbarPageComponent implements OnInit {
  @ViewChild(VenueTemplateListComponent) VenueTemplateListComponent!: VenueTemplateTableComponent;
  private selectedVenueService = inject(SelectedVenueService);
  venue!: Venue;

  override ngOnInit(): void {
    super.ngOnInit();
    this.selectedVenueService.item.pipe(
      takeUntilDestroyed(this.destroy),
      filter((venue) => venue != null)).subscribe((venue) => {
      this.venue = venue!;
      this.setBreadcrumbAlias("@VenueName", this.venue.name);
    });
    const venue = this.route.snapshot.data['venue'] as Venue;
    this.selectedVenueService.setItem(venue);
    this.setBreadcrumbAlias("@VenueName", this.venue.name);
    this.setToolbarActions([
      {
        id: "details",
        icon: "info_outlined",
        title: "Venue Details",
        description: "View details about this venue",
        newRoute: ["/venue", this.venue.id!.toString()],
      },
      {
        id: "template",
        icon: "square_foot",
        title: "Venue Template",
        description: "Templates for this venue",
        newRoute: ["/venue", this.venue.id!.toString(), "template"],
      },
      {
        id: "location",
        icon: "storefront_outlined",
        title: "Venue Locations",
        description: "Locations for this venue",
        newRoute: ["/venue", this.venue.id!.toString(), "location"],
      },
      {
        id: "map",
        icon: "map",
        title: "Venue Map",
        description: "Map for this venue",
        disabled: !this.venue.map_url,
        newRoute: ["/venue", this.venue.id!.toString(), "map"],
      },
    ]);
  }
}
