import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { commonModules } from 'src/app/app.config';
import { Favorite, Location } from 'src/app/planvue-api';

export interface OperationType {
  value: 'new' | 'existing' | 'remove';
  label: string;
}

export interface FavoriteDialogConfig {
  location: Location
  userFavorites: Array<Favorite>;
  alreadyFavoritedLists: Array<Favorite>;
}

export interface FavoriteDialogResult {
  listType: 'new' | 'existing' | 'remove';
  newFavoriteName: string | null;
  selectedFavorites: Favorite[] | null;
}

@Component({
  selector: 'app-favorite-location-dialog',
  templateUrl: './favorite-location-dialog.component.html',
  standalone: true, 
  imports: [...commonModules, FormsModule],
  styleUrl: './favorite-location-dialog.component.scss'
})
export class FavoriteLocationDialogComponent {
  private readonly fb = inject(FormBuilder);
  private readonly destroy = inject(DestroyRef);

  location: Location;
  operation:'new' | 'existing' | 'remove' = 'new';
  operationTypes: Array<OperationType>;
  
  usersFavorites: Array<Favorite> = [];
  userHasFavorites: boolean = false;
  
  alreadyFavoritedLists: Array<Favorite> = [];
  userHasAlreadyFavorited: boolean = false;

  // This is the array of Favorites that's actually used in the template for 
  // rendering the drop-down list; it's set based on the operation type and
  // gets set to one of the above Favorites arrays (userFavorites or alreadyFavoritedLists)
  // depending on th eoperation.
  favorites: Array<Favorite> = [];
  
  // the form displayed in the dialog
  dialogForm!:FormGroup;

  constructor(public dialog: MatDialogRef<FavoriteLocationDialogComponent, FavoriteDialogResult | null>,  @Inject(MAT_DIALOG_DATA) public config: FavoriteDialogConfig) {
    this.location = config.location;
    this.usersFavorites = config.userFavorites;
    this.alreadyFavoritedLists = config.alreadyFavoritedLists;
    this.userHasFavorites = this.usersFavorites ? this.usersFavorites.length > 0 : false;
    this.userHasAlreadyFavorited = this.alreadyFavoritedLists ? this.alreadyFavoritedLists.length > 0 : false;

    // determine which operationTypes (radio buttons) are present in the UI
    this.operationTypes = [{ value: 'new', label: 'Add to a new list' }];
    if (this.userHasFavorites) {
      this.operationTypes.push({ value: 'existing', label: 'Add to existing list' });
    }
    if (this.alreadyFavoritedLists && this.alreadyFavoritedLists.length > 0) {
      this.operationTypes.push({ value: 'remove', label: 'Remove from list' });
    }

    // determine which operationType is selected by default
    let operationType:'new' | 'existing' | 'remove' = 'new';
    if (this.userHasAlreadyFavorited) {
      // if the location is already favorited, then assume the user wants to remove it
      operationType = 'remove';
    } else if (this.userHasFavorites) {
      operationType = 'existing';
    }
    
    this.dialogForm= this.fb.group({
      operationType: this.fb.nonNullable.control(operationType, [Validators.required]),
      selectedFavorites: this.fb.nonNullable.control([]),
      newListName: this.fb.nonNullable.control(null)
    });

    this.configureFormFields(operationType);
  }

  updateFormFields(event: MatRadioChange): void {
      const operationType = event.value;
      this.configureFormFields(operationType);
  }

  configureFormFields(operationType:'new' | 'existing' | 'remove'):void {
    this.operation = operationType;
    
    const newListNameCtrl = this.dialogForm.controls['newListName'];
    const selectedFavoritesCtrl = this.dialogForm.controls['selectedFavorites'];
    
    if (operationType === 'new') {
      selectedFavoritesCtrl.clearValidators();
      selectedFavoritesCtrl.disable();

      newListNameCtrl.enable();
      newListNameCtrl.setValidators(Validators.required);
      this.favorites = [];
    } else if (operationType === 'existing' || operationType === 'remove') {
      newListNameCtrl.clearValidators();
      newListNameCtrl.disable();
      
      selectedFavoritesCtrl.enable();
      selectedFavoritesCtrl.setValidators(Validators.required);
      if (operationType === 'existing') {
        this.favorites = this.usersFavorites;
      } else {
        this.favorites = this.alreadyFavoritedLists;
      }
    }

    // always clear the fields' values when changing the operation type
    selectedFavoritesCtrl.setValue([]);
    newListNameCtrl.setValue(null);
  }

  onDismiss(): void {
    this.dialog.close(null);
  }

  onConfirm(): void {
    const newListNameCtrl = this.dialogForm.controls['newListName'];
    const selectedFavoritesCtrl = this.dialogForm.controls['selectedFavorites'];

    const result: FavoriteDialogResult = {
      listType: this.operation,
      selectedFavorites: selectedFavoritesCtrl.value,
      newFavoriteName: newListNameCtrl.value
    };

    this.dialog.close(result);
  }
}
