export const $Page_Template_ = {
	properties: {
		items: {
	type: 'array',
	contains: {
		type: 'Template',
	},
	isRequired: true,
},
		info: {
	type: 'PageInfo',
	isRequired: true,
},
	},
} as const;