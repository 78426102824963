import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { commonModules } from 'src/app/app.config';
import { PagedToolbarComponentWithSelectedVenueComponent } from 'src/app/components/base-classes/paged-toolbar-component';
import { VenueTemplateUploadDialogComponent } from 'src/app/components/dialog/venue-template-upload-dialog/venue-template-upload-dialog.component';
import { VenueTemplateTableComponent } from 'src/app/components/venue-template-table/venue-template-table.component';
import { ToolbarAction } from 'src/app/models/toolbar-action';
import { Template, VenueTemplateService } from 'src/app/planvue-api';
import { VenueTemplatesDataSource } from '../../datasources/venue-templates-datasource';


@Component({
  selector: 'app-venue-template-list',
  templateUrl: './venue-template-list.component.html',
  styleUrl: './venue-template-list.component.scss',
  standalone: true,
  imports: [...commonModules, VenueTemplateTableComponent],
})
export class VenueTemplateListComponent extends PagedToolbarComponentWithSelectedVenueComponent<Template, VenueTemplatesDataSource> implements OnInit, OnDestroy {
  private readonly dialog = inject(MatDialog);
  private readonly templateService = inject(VenueTemplateService);
  protected override datasource = new VenueTemplatesDataSource();

  override ngOnInit(): void {
    super.ngOnInit();

    this.addToolbarActionToFront({
      id: 'add-template',
      icon: 'add',
      title: 'Add Template',
      addDivider: true,
      description: 'Add a new template',
      requiredRoles: ['admin'],
    });
    this.datasource.venue = this.venue;
    const role = this.route.snapshot.data['currentUser'].role;
    if (role === "user" || role === "inhouse") {
      this.datasource.columns = this.datasource.columns.filter(column => column !== 'delete');
    }
    this.datasource.loadItems();
  }

  ngOnDestroy(): void {
    this.removeToolbarAction('add-template');
  }

  protected deleteTemplate(template: Template): void {
    this.templateService.deleteVenueTemplate(template.id!).subscribe(() => {
      this.datasource.removeItem(template);
      this.notifySuccess("Template deleted");
    });
  }

  protected override toolbarActionClicked(action: ToolbarAction): void {
    if (action.id == 'add-template') {
      const dialogRef = this.dialog.open(VenueTemplateUploadDialogComponent, {
        data: { venue: this.datasource.venue }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.datasource.loadItems();
        }
      });
    }
  }
}
