
<form [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <textarea matInput formControlName="name" placeholder="Location Name">
        </textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" placeholder="Location Description">
        </textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Bleed</mat-label>
        <textarea matInput formControlName="bleed" placeholder="Bleed">
        </textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Dimensions</mat-label>
        <textarea matInput formControlName="dimensions" placeholder="Bleed">
        </textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Suggested Material (generic)</mat-label>
        <textarea matInput type="input" formControlName="suggested_material_generic"
            placeholder="Suggested Material (generic)">
        </textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Suggested Material (specific)</mat-label>
        <textarea matInput formControlName="suggested_material_specific"
            placeholder="Suggested Material (specific)"></textarea>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Designers Note</mat-label>
        <textarea matInput formControlName="designers_note" placeholder="Designers Note"></textarea>
    </mat-form-field>
</form>