<section>
    <table mat-table [dataSource]="datasource">
        <!-- Name Column Definition -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let location"> {{ location.name }} </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let location"> {{ location.description }} </td>
        </ng-container>
        <ng-container matColumnDef="view_on_map">
            <th mat-header-cell *matHeaderCellDef> View On Map </th>
            <td mat-cell *matCellDef="let location"><mat-icon [fontIcon]="location.is_linked ? 'visibility': 'visibility_off'" [color]="getLinkColor(location)" (click)="viewLocationOnMap(location, $event)"></mat-icon> </td>
        </ng-container>
        <ng-container matColumnDef="image_count">
            <th mat-header-cell *matHeaderCellDef> Images </th>
            <td mat-cell *matCellDef="let location"> {{ location.image_count }} </td>
        </ng-container>
        <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let location"> {{ getCreatedBy(location.created_by) }} </td>
        </ng-container>
        <ng-container matColumnDef="updated_on">
            <th mat-header-cell *matHeaderCellDef> Updated On </th>
            <td mat-cell *matCellDef="let location"> {{ location.updated_at | date: 'M/dd/yyyy hh:mm a' }} </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Delete </th>
            <td mat-cell *matCellDef="let location"><mat-icon (click)="deleteLocation(location, $event)" fontSet="material-icons-outlined">delete></mat-icon> </td>
        </ng-container>
        <tr mat-row *matRowDef="let location; columns: columns;" 
         class="venue-location-row" (click)="selectLocation(location)"></tr>
        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr class="mat-row" *matNoDataRow [hidden]="datasource.loading$ | async">
            <td class="mat-cell no-match" [attr.colspan]="columns.length">{{ getNoDataMessage() }}</td>
        </tr>
    </table>
</section>