export const $FavoriteAdd = {
	properties: {
		name: {
	type: 'string',
	isRequired: true,
},
		user_id: {
	type: 'any-of',
	contains: [{
	type: 'number',
}, {
	type: 'null',
}],
},
		locations: {
	type: 'any-of',
	contains: [{
	type: 'array',
	contains: {
	type: 'number',
},
}, {
	type: 'null',
}],
},
	},
} as const;