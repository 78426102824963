import { CollectionViewer, ListRange } from "@angular/cdk/collections";
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { commonModules } from 'src/app/app.config';
import { VenuesDataSource } from 'src/app/datasources/venues-datasource';
import { BackgroundImageDirective } from 'src/app/directives/background-image.directive';
import { Venue } from 'src/app/planvue-api';

@Component({
  selector: 'app-venue-grid',
  templateUrl: './venue-list-grid.component.html',
  standalone: true,
  imports: [...commonModules, BackgroundImageDirective],
  styleUrls: ['./venue-list-grid.component.scss']
})
export class VenueGridComponent implements OnInit, CollectionViewer {
  public venues: Venue[] = [];
  public viewChange: Observable<ListRange> = new Observable<ListRange>();
  @Input()
  public datasource:VenuesDataSource = new VenuesDataSource();

  ngOnInit(): void {
    this.datasource.connect(this).subscribe((venues) => {
      this.venues = [...venues];
    });
  }

  getImage(venue:Venue):string {
    return venue.image_url ?? '';
  }
}
