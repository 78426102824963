<form [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="input" formControlName="name"
            placeholder="Venue Name"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput type="textarea" formControlName="description"
            placeholder="Venue Description"/>
    </mat-form-field>
</form>