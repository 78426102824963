import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FilteredPagedItemsDataSourceComponent } from 'src/app/components/base-classes/filtered-paged-items-datasource';
import { Filter } from 'src/app/datasources/favorite-datasource';
import { PagedToolbarComponent, PagedToolbarComponentWithSelectedVenueComponent } from './paged-toolbar-component';



@Component({
  selector: 'app-filtered-paged-toolbar',
  template: '',
})
export abstract class FilteredPagedToolbarComponent<I, F extends Filter, X extends FilteredPagedItemsDataSourceComponent<I, F>> extends PagedToolbarComponent<I, X> implements OnInit {
  protected filterValue$: Subject<F> = new Subject<F>();

  constructor() {
    super();
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.filterValue$.subscribe(filter => {
      this.paginator.pageIndex = 0;
      this.datasource.pageIndex = 0;
      this.datasource.filter = filter;
      this.datasource.loadItems();
    });
  }
}

@Component({
  selector: 'app-venue-filtered-paged-toolbar',
  template: '',
})
export abstract class FilteredPagedToolbarComponentWithSelectedVenueComponent<I, F extends Filter, X extends FilteredPagedItemsDataSourceComponent<I, F>> extends PagedToolbarComponentWithSelectedVenueComponent<I, X> implements OnInit {
  protected filterValue$: Subject<F> = new Subject<F>();

  constructor() {
    super();
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.filterValue$.subscribe(filter => {
      this.paginator.pageIndex = 0;
      this.datasource.pageIndex = 0;
      this.datasource.filter = filter;
      this.datasource.loadItems();
    });
  }
}

