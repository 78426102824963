export const $HealthCheck = {
	properties: {
		status: {
	type: 'string',
	isRequired: true,
},
		ref: {
	type: 'string',
	isRequired: true,
},
		sha: {
	type: 'string',
	isRequired: true,
},
	},
} as const;