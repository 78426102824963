import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import * as Sentry from "@sentry/angular-ivy";
import { ReplaySubject, Subject, filter } from 'rxjs';
import { ProfileService as APIProfileService, User } from '../planvue-api';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  auth0 = inject(AuthService);
  profileService = inject(APIProfileService);
  user$: Subject<User> = new ReplaySubject<User>();

  constructor() { 
    this.auth0.user$.pipe(filter((user) => user != null)).subscribe(() => {
      this.profileService.getMyProfile().subscribe(profile => {
        Sentry.setUser({ email: profile.email, id: String(profile.id) });
        this.user$.next(profile);
      });
    });
  }

  public getPrefence(key: string, defaultValue?:string):string {
    const value = localStorage.getItem(key);
    if (value) {
      return value;
    }
    
    if (defaultValue) {
      return defaultValue;
    }

    throw new Error('Preference not found and no default supplied: ' + key);
  }

  public setPreference(key: string, value: string) : void {
    localStorage.setItem(key, value);
  }


  public impersonateUser(email: string) : void {
    localStorage.setItem('Planvue-Impersonated-User', email);
    window.location.href = '/welcome';
  }

  public stopImpersonating() : void {
    localStorage.removeItem('Planvue-Impersonated-User');
    window.location.href = '/welcome';
  }

  public isImpersonating() : boolean {
    return localStorage.getItem('Planvue-Impersonated-User') !== null;
  }
}
