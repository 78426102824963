export const $LocationImage = {
	properties: {
		id: {
	type: 'number',
	isRequired: true,
},
		idx: {
	type: 'number',
	isRequired: true,
},
		image_url: {
	type: 'string',
	isRequired: true,
},
	},
} as const;