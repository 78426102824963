import { Component, DestroyRef, Inject, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStep, MatStepper, } from '@angular/material/stepper';
import { BehaviorSubject, Subject } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { BaseComponent } from 'src/app/components/base-classes/base-component';
import { ConfirmationConfig, ConfirmationDialogComponent } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { UploadComponent, UploadType } from 'src/app/components/upload/upload.component';
import { Image, ImageService, Location, LocationAdd, LocationImage, LocationService, LocationUpdate, Venue } from 'src/app/planvue-api';
import { LocationForm, LocationFormComponent } from './location-form.component';

interface CreateLocationDialogData {
  venue: Venue
}

interface EditLocationDialogData {
  location: Location
}

interface DisplayedImage {
  type: 'image' | 'location_image'
  image: Image | LocationImage
  url: string
}

@Component({
  template: '',
})
export abstract class BaseLocationDialogComponent extends BaseComponent {
  destroy = inject(DestroyRef)
  private readonly dialog = inject(MatDialog);
  readonly locationService = inject(LocationService);
  private readonly imageService = inject(ImageService)
  protected readonly dirty$ = new BehaviorSubject<boolean>(false);
  protected abstract saveLabel: string;
  dialogRef: MatDialogRef<BaseLocationDialogComponent>;
  location_images: LocationImage[] = []
  new_images: Image[] = []
  displayed_images: DisplayedImage[] = []
  imageUploadedSubject: Subject<string | null> = new Subject<string | null>();
  readonly uploadFileType: UploadType = UploadType.IMAGE;
  protected abstract dialogTitle: string;

  locationForm = new FormGroup<LocationForm>({
    name: new FormControl<string | null>(null, { validators: [Validators.required] }),
    description: new FormControl<string | null>(null),
    bleed: new FormControl<string | null>(null),
    dimensions: new FormControl<string | null>(null),
    suggested_material_generic: new FormControl<string | null>(null),
    suggested_material_specific: new FormControl<string | null>(null),
    designers_note: new FormControl<string | null>(null),
  })

  private readonly DELETE_IMAGE_CONFIRMATION_CONFIG: ConfirmationConfig = {
    title: "Delete Image",
    message: "Are you sure you want to delete this image?",
    confirmText: "Delete",
    cancelText: "Cancel",
  };

  @ViewChild(UploadComponent)
  uploadComponent?: UploadComponent;

  @ViewChild("stepper", { static: true })
  stepper?: MatStepper;

  @ViewChild("detailsStep", { static: true })
  detailsStep?: MatStep;

  constructor(dialogRef: MatDialogRef<BaseLocationDialogComponent>) {
    super()
    this.dialogRef = dialogRef;
  }

  abstract save(): void;

  deleteImage(image: DisplayedImage): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: "400px",
      data: this.DELETE_IMAGE_CONFIRMATION_CONFIG
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        if (image.type === 'location_image') {
          this.displayed_images = this.displayed_images.filter((i) => i.image !== image.image)
        } else {
          this.imageService.deleteImage(image.image.id!).subscribe(() => {
            this.new_images = this.new_images.filter((i) => i !== image.image)
            this.displayed_images = this.displayed_images.filter((i) => i.image !== image.image)
          });
        }
      }
    });
  }

  onImageSelected(file: File): void {
    this.imageService.uploadImage({
      uploaded_file: file
    }).subscribe((image) => {
      this.uploadComponent!.uploadComplete();
      this.new_images.push(image)
      this.displayed_images.push({
        type: 'image',
        image: image,
        url: image.url
      })
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-location-edit',
  standalone: true,
  imports: [...commonModules, LocationFormComponent, UploadComponent],
  templateUrl: './location-dialog.component.html',
  styleUrl: './location-dialog.component.scss'
})
export class EditLocationDialogComponent extends BaseLocationDialogComponent {
  location_id!: number
  saveLabel: string = "Save"
  protected override dialogTitle: string;
  location!: Location

  constructor(dialogRef: MatDialogRef<EditLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) data: EditLocationDialogData) {
    super(dialogRef)
    this.location = data.location
    this.dialogTitle = `Edit ${this.location.name}`
    this.location_id = data.location.id!
    this.displayed_images = data.location.images.map((image) => {
      return {
        type: 'location_image',
        image: image,
        url: image.image_url
      }
    })
    this.locationForm.patchValue(this.location)
  }

  save(): void {
    const update = {
      ... this.locationForm.value,
      images: this.displayed_images.map((image, idx) => {
        return {
          type: image.type,
          idx: idx,
          id: image.type === 'location_image' ? (image.image as LocationImage).id : (image.image as Image).id
        }
      })
    } as LocationUpdate
    this.locationService.updateLocation(this.location_id!, update).subscribe((location) => {
      this.dialogRef.close(location);
      this.notifySuccess('The location was updated successfully.');
    })
  }
}


@Component({
  selector: 'app-location-new',
  standalone: true,
  imports: [...commonModules, LocationFormComponent, UploadComponent],
  templateUrl: './location-dialog.component.html',
  styleUrl: './location-dialog.component.scss'
})
export class NewLocationDialogComponent extends BaseLocationDialogComponent {
  saveLabel: string = "Create"
  venue_id!: number
  protected override dialogTitle;

  constructor(dialogRef: MatDialogRef<NewLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) data: CreateLocationDialogData) {
    super(dialogRef)
    this.venue_id = data.venue.id!
    this.dialogTitle = `New Location for ${data.venue.name}`
  }

  save(): void {
    // On an add, all the images are considered to be new images.
    const add = { 
      ... this.locationForm.value,
      images: this.displayed_images.filter((i) => i.type === 'image').map((i) => i.image as Image)
    } as LocationAdd

    this.locationService.addLocation(this.venue_id, add).subscribe((location) => {
      this.dialogRef.close(location);
      this.notifySuccess('The location was created successfully.');
    })
  }
}