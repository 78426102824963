import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { Location, Page_Location_, Venue, VenueService } from "src/app/planvue-api";
import { FilteredPagedItemsDataSourceComponent } from "../components/base-classes/filtered-paged-items-datasource";

export interface Filter { 
    searchTerm?: string;
}

export interface LocationFilter extends Filter {
    linked?: string;
}

export class VenueLocationsDataSource extends FilteredPagedItemsDataSourceComponent<Location, LocationFilter> {
    public venue!: Venue;
    private readonly venueService: VenueService = inject(VenueService);
    public columns = [ "name", "description", "view_on_map", "image_count", "created_by", "updated_on", "delete" ];

    override requestItemsWithFilter(pageIndex: number, pageSize: number, filter: LocationFilter): Observable<Page_Location_> {
        return this.venueService.getVenueLocations(
            this.venue.id!,
            pageIndex + 1,
            pageSize,
            filter.searchTerm,
            filter.linked
            );  
          }

    override requestItems(pageIndex:number, pageSize: number): Observable<Page_Location_> {
        return this.venueService.getVenueLocations(
            this.venue.id!,
            pageIndex + 1,
            pageSize);
    }
}