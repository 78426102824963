<div class="location" *ngIf="location">
    <div class="details-header">
        <p class="description">{{ location.description }}</p>
    </div>
    <div class="details-content">
        <ng-image-slider #carousel *ngIf="images.length > 0; else noimages"
            [infinite]="false"
            [defaultActiveImage]="1"
            [images]="images" 
            [showArrow]="images.length > 2 ? true : false" 
            [imageSize]="imageSize" 
            [animationSpeed]=".3"
            [slideImage]="1">
        </ng-image-slider>
        <div class="wrapper" *ngIf="showDetails">
            <div class="data" *ngIf="location.dimensions">
                <div class="row1">
                    <mat-icon>zoom_out_map</mat-icon>
                    <span>Dimensions</span>
                </div>
                <div class="row2">
                    {{ location.dimensions }}
                </div>
            </div>
            <div class="data" *ngIf="location.bleed">
                <div class="row1">
                    <mat-icon>border_outer</mat-icon>
                    <span>Bleed</span>
                </div>
                <div class="row2">
                    {{ location.bleed }}
                </div>
            </div>
            <div class="data" *ngIf="location.suggested_material_generic">
                <div class="row1">
                    <mat-icon fontSet="material-icons-outlined">article</mat-icon>
                    <span>Suggested Material</span>
                </div>
                <div class="row2">
                    {{ location.suggested_material_generic }}
                </div>
            </div>
            <div class="data" *ngIf="location.suggested_material_specific">
                <div class="row1">
                    <mat-icon>article</mat-icon>
                    <span>Suggested Material Specific</span>
                </div>
                <div class="row2">
                    {{ location.suggested_material_specific }}
                </div>
            </div>
            <div class="data" *ngIf="location.designers_note">
                <div class="row1">
                    <mat-icon fontSet="material-icons-outlined">assignment</mat-icon>
                    <span>Desinger Notes</span>
                </div>
                <div class="row2">
                    {{ location.designers_note }}
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noimages>
    <h2>No Images Available</h2>
</ng-template>