import { Component, Injectable, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, AsyncValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, catchError, map, of, startWith, switchMap } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { ConfirmationConfig, ConfirmationDialogComponent } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { CanComponentDeactivate } from 'src/app/guards/can-deactivate.guard';
import { ApiError, User, UserAdd, UserRole, UserService, UserUpdate, UserVenue, VenueService } from 'src/app/planvue-api';
import { DisplayedVenue, UserForm, UserFormComponent } from './user-form.component';


import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { ToolbarPageComponent } from 'src/app/components/base-classes/toolbarpage-component';


export const venuesRequiredForUserRole: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const role = control.get('role');
  const venues = control.get('venues');
  const dirty = control.dirty
  return dirty && role && venues && role.value === 'user' && (venues.value == null || venues.value.length === 0) ? { 'venuesRequired': true } : null;
};

@Injectable({ providedIn: 'root' })
export class UniqueEmailValidator implements AsyncValidator {
  private initialValue?: string
  constructor(private userService: UserService) {}

  setInitialValue(value: string) {
    this.initialValue = value;
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (this.initialValue === control.value) {
      return of(null);
    }

    if (!control.value)
      return of({ nothing: true });
    return this.userService.checkEmailExists(control.value).pipe(
      map(() => { 
        return null; // No error.
      }),
      catchError((error: ApiError) => { 
        if (error.status === 409) {
          return of({ already_exists: true }); // Email already exists.
        }
        throw error;
      })
    );
  }
}

@Component({
  template: '',
})
export abstract class BaseUserFormComponent extends ToolbarPageComponent implements CanComponentDeactivate {
  protected readonly dialog = inject(MatDialog);
  protected readonly venueService = inject(VenueService)
  protected readonly userService = inject(UserService);
  protected readonly uniqueEmailValidator = inject(UniqueEmailValidator);
  protected venues: DisplayedVenue[] = []; // The venues assigned to the user.
  protected currentUser: User; // The current user.
  protected buttonLabel = "Save"; // The label for the save button.
  protected abstract mode: string;
  protected permitVenueAssignment = true;
  protected showAssignedVenues = true;

  userForm: FormGroup<UserForm> = new FormGroup<UserForm>({
    first_name: new FormControl<string | null>('', { validators: [Validators.required], nonNullable: true }),
    last_name: new FormControl<string | null>('', { validators: [Validators.required], nonNullable: true }),
    email: new FormControl<string | null>(null, { validators: [Validators.required, Validators.email], asyncValidators: [this.uniqueEmailValidator.validate.bind(this.uniqueEmailValidator)] }),
    role: new FormControl<UserRole | null>("user", { validators: [Validators.required] }),
    organization: new FormControl<string | null>(null),
    active: new FormControl<boolean | null>(true),
    venues: new FormControl<[] | null>([]),
  }, { validators: [venuesRequiredForUserRole] });

  protected readonly DIRTY_CONFIRMATION_CONFIG: ConfirmationConfig = {
    title: "Unsaved Changes",
    message: "Would you like to continue and lose your changes?",
    confirmText: "Continue",
    cancelText: "No, Stay Here",
  };

  constructor() {
    super();
    this.currentUser = this.route.snapshot.data['currentUser'];
  }

  protected setupVenues(userVenues: UserVenue[] = [] || null): void {
    this.venueService.getVenues().subscribe((venues) => {
        this.venues = venues.items.sort((a, b) => a.name.localeCompare(b.name)).
        map(v => {
          return {
            name: v.name,
            id: v.id!,
            selected: userVenues.some(uv => uv.id === v.id),
          };
        });
    });
  }

  canDeactivate(): Observable<boolean> {
    return this.userForm.valueChanges.pipe(
      startWith(null),
      takeUntilDestroyed(this.destroy),
      map(() => this.userForm.dirty), 
      switchMap((dirty) => {
      if (!dirty) return of(true);
          return this.dialog.open(ConfirmationDialogComponent, { data: this.DIRTY_CONFIRMATION_CONFIG }).afterClosed();
       })
    )
  }
  
  abstract save(): void;
  abstract delete(): void;
  send_reset_email(): void { }

  reset() {
    this.userForm.reset();
  }
}

@Component({
  selector: 'app-new-user',
  templateUrl: './user.component.html',
  standalone: true,
  imports: [...commonModules, UserFormComponent, ToolbarComponent],
  styleUrl: './user.component.scss'
})
export class NewUserComponent extends BaseUserFormComponent implements OnInit, CanComponentDeactivate {
  protected override mode = 'add'

  override ngOnInit(): void {
    super.ngOnInit();
    this.currentUser = this.route.snapshot.data['currentUser']
    this.setupVenues();
    this.buttonLabel = "Create"
    this.userForm.markAsPristine();

    this.userForm.controls['role'].valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe((role) => {
      if (this.currentUser.role === 'admin' &&  (role === 'admin')) {
        this.permitVenueAssignment = false; 
        this.userForm.controls['venues'].setValue([]);
      } else {
        this.permitVenueAssignment = true;  
        this.userForm.controls['venues'].enable();
      }
    });
  }
  override delete(): void {
    throw new Error("Method not implemented")
  }

  override save(): void {
    const userAdd : UserAdd= { 
      first_name: this.userForm.value.first_name!,
      last_name: this.userForm.value.last_name!,
      email: this.userForm.value.email!,
      role: this.userForm.value.role!,
      organization: this.userForm.value.organization,
      active: this.userForm.value.active!,
      venues: this.userForm.value.venues
    }

    this.userService.addUser(userAdd).pipe(catchError((error: ApiError) => {
      if (error.status === 409) {
        this.userForm.controls['email'].setErrors({ 'already_exists': true });
        return of(null)
      }
      throw error;
    })).
    subscribe((user: User | null) => {
      if (user) { 
        this.reset();
        this.notifySuccess(`User ${user.email} added successfully.`);
        this.router.navigate(['/user']);
      }
    });
  }

}

@Component({
  selector: 'app-edit-user',
  templateUrl: './user.component.html',
  standalone: true,
  imports: [...commonModules, ToolbarComponent, UserFormComponent],
  styleUrl: './user.component.scss'
})
export class EditUserComponent extends BaseUserFormComponent implements OnInit, CanComponentDeactivate {
  protected override mode = 'edit'
  protected user! : User;

  override ngOnInit(): void {
    super.ngOnInit();
    this.user = this.route.snapshot.data['user'] as User;
    this.currentUser = this.route.snapshot.data['currentUser']
    this.uniqueEmailValidator.setInitialValue(this.user.email);
    this.mode = this.user.id == this.currentUser.id  ? 'selfedit': 'edit'
    this.buttonLabel = "Save"
    this.setupVenues(this.user.venues ?? []);
    this.reset();

    if (this.mode === 'selfedit') {
      this.userForm.controls['role'].disable();
      this.userForm.controls['active'].disable();
    }

    this.userForm.controls['role'].valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe((role) => {
      if (this.currentUser.role === 'admin' &&  (role === 'admin')) {
        this.permitVenueAssignment = false; 
        this.userForm.controls['venues'].setValue([]);
        this.userForm.controls['venues'].disable();
      } else {
        this.permitVenueAssignment = true;  
        this.userForm.controls['venues'].enable();
      }
    });
  }

  override send_reset_email(): void { 
    const user_id = this.route.snapshot.data['user'].id!;
    this.userService.sendPasswordReset(user_id).subscribe(() => {
      this.notifySuccess("Password reset email has been sent.");
    });
  }


  override delete(): void { 
    const user_id = this.route.snapshot.data['user'].id!;
    this.userService.deleteUser(user_id).subscribe(() => {
      this.notifySuccess("User deleted successfully.");
      this.router.navigate(['/user']);
    });
  }

  override save(): void {
    const user_id = this.route.snapshot.data['user'].id!;
    const value = this.userForm.value;
    const userUpdate : UserUpdate = {  ... value }
    this.userService.updateUser(user_id, userUpdate).subscribe(() => {
      this.userForm.reset();
      this.notifySuccess("User updated successfully.");
      this.router.navigate(['/user']);
    });
  }

  override reset() : void { 
    this.userForm.reset( { 
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      role: this.user.role,
      organization: this.user.organization,
      active: this.user.active,
      venues: this.user.venues?.map(v => v.id) ?? []
    })
  }

}