<h1 mat-dialog-title>
    Upload template to {{ venue.name }}
</h1>
<div mat-dialog-content>
    <app-upload 
    (fileSelected)="onFileSelected($event)"
    [uploadType]="uploadFileType">
    </app-upload>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
