<div mat-dialog-title>{{ dialogTitle }} </div>
<div class="content">
    <mat-stepper linear="true" #stepper>
        <mat-step #detailsStep label="Details" [completed]="locationForm.valid">
            <div mat-dialog-content>
                <app-location-form [formGroup]="locationForm"></app-location-form>
            </div>
            <div mat-dialog-actions>
                <button mat-raised-button (click)="cancel()">Cancel</button>
                <button mat-raised-button color="primary" (click)="stepper.next()"
                    [disabled]="!detailsStep.completed">Next</button>
            </div>
        </mat-step>
        <mat-step #imagesStep label="Images" editable="false">
            <div mat-dialog-content class="images-wrapper">
                <div class="images">
                    <ng-container *ngIf="displayed_images.length ?? 0 > 0 ; else noimages">
                        <div class="image" *ngFor="let image of displayed_images">
                            <img [src]="image.url" alt="Location Image">
                            <button matTooltip="Delete" (click)="deleteImage(image)" aria-label="Delete this image">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
                <app-upload [uploadType]="uploadFileType" (fileSelected)="onImageSelected($event)"
                    [uploadEvents]="imageUploadedSubject.asObservable()" [uploadText]="'Upload Image'"
                    [showImagePreview]="false"></app-upload>
         
            </div>
            <div mat-dialog-actions>
                <button mat-raised-button (click)="cancel()">Cancel</button>
                <button mat-raised-button color="primary" matStepperPrevious>Previous</button>
                <button mat-raised-button color="primary" (click)="save()">{{
                    saveLabel }}</button>
            </div>
        </mat-step>
    </mat-stepper>
</div>

<ng-template #noimages>
    <div class="no-images">No Images</div>
</ng-template>