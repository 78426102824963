import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { commonModules } from 'src/app/app.config';
import { VenueLocationsDataSource } from 'src/app/datasources/venue-locations-datasource';
import { Location, LocationService, User } from 'src/app/planvue-api';
import { PagedTableComponent } from '../base-classes/paged-table';
import { ConfirmationConfig, ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-venue-location-table',
  templateUrl: './venue-location-table.component.html',
  styleUrl: './venue-location-table.component.scss',
  standalone: true,
  imports: [...commonModules]
})
export class VenueLocationTableComponent extends PagedTableComponent<Location, VenueLocationsDataSource> implements OnInit{
  private readonly dialog = inject(MatDialog);
  private readonly locationService = inject(LocationService);

  private readonly DELETE_CONFIRMATION_CONFIG: ConfirmationConfig = {
    title: "Delete Location",
    message: "Are you sure you want to delete this location?",
    confirmText: "Delete",
    cancelText: "Cancel",
  };

  @Output()
  viewLocationOnMapSelected: EventEmitter<Location> = new EventEmitter<Location>();

  @Output()
  locationSelected: EventEmitter<Location> = new EventEmitter<Location>();

  constructor() { 
    super();
  }

  ngOnInit(): void {
    this.columns = this.datasource.columns;
  }

  getNoDataMessage(): string {
    return 'No Venue Locations found.';
  }

  getCreatedBy(user: User): string {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    }
    return '';
  }

  selectLocation(location: Location): void {
    this.locationSelected.emit(location);
  }

  getLinkColor(location: Location): string { 
    return location.is_linked ? '' : 'warn';
  }

  viewLocationOnMap(location: Location, event: MouseEvent): void {
    if (location.is_linked) {
      this.viewLocationOnMapSelected.emit(location);
    }
    event.stopPropagation();
  }


  deleteLocation(location: Location, event:MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "400px",
      data: this.DELETE_CONFIRMATION_CONFIG
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.locationService.deleteLocation(location.id!).subscribe(() => {
          this.datasource.loadItems();
          this.notifySuccess('Location deleted successfully.');
        });
      }
    });
  }
}
