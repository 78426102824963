import { Component, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DEFAULT_SUCCESS_SNACKBAR_CONFIG } from "src/app/app.constants";


@Component({
    template: ''
})
export abstract class BaseComponent { 
    protected snackBarService = inject(MatSnackBar);
    constructor() {
    }
    
    public notifySuccess(message: string) {
        this.snackBarService.open(message, '', DEFAULT_SUCCESS_SNACKBAR_CONFIG);

    }
    public notifyError(message: string) {
        this.snackBarService.open(message, '', DEFAULT_SUCCESS_SNACKBAR_CONFIG);
    }
}