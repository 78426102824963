import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { HealthCheck } from '../models/HealthCheck';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class HealthcheckService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Healthcheck
	 * Healthcheck endpoint.
	 * @returns HealthCheck Successful Response
	 * @throws ApiError
	 */
	public getApiHealthcheck(): Observable<HealthCheck> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/healthcheck',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Error
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public errorHealthcheckErrorGet(): Observable<unknown> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/healthcheck/error',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}