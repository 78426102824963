<div class="container">
    <img *ngIf="uploadType === UploadType.IMAGE && showImagePreview" [src]="fileUrl ? fileUrl : '/assets/images/no_image.jpg'" alt="image"
        class="img-thumbnail">
    <mat-progress-bar mode="indeterminate" *ngIf="uploading"></mat-progress-bar>
    <div class="file-info">
        <input type="file" #fileUpload class="file-input" (change)="selectFile($event)" [accept]="uploadType">
        @if (getFilename()) {
            <span>{{ getFilename() }}</span>
        } @else {
            <button mat-button color="accent"(click)="fileUpload.click()">{{ uploadText }}</button>
        }
        <button (click)="fileUpload.click()" mat-icon-button aria-label="Perform Upload" *ngIf="!fileUrl">
            <mat-icon color="accent" fontSet="material-icons-outlined">cloud_upload</mat-icon>
        </button>
        <button (click)="clear()" mat-icon-button aria-label="Clear" *ngIf="fileUrl">
            <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        </button>
    </div>
</div>