import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Page_Template_ } from '../models/Page_Template_';
import type { Template } from '../models/Template';
import type { TemplateUpdate } from '../models/TemplateUpdate';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class VenueTemplateService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Get Venue Templates
	 * @param page 
	 * @param pageSize 
	 * @returns Page_Template_ Successful Response
	 * @throws ApiError
	 */
	public getAllVenueTemplates(
page: number = 1,
pageSize: number = 25,
): Observable<Page_Template_> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/template',
			query: {
				page, page_size: pageSize
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Venue Template
	 * @param templateId 
	 * @returns Template Successful Response
	 * @throws ApiError
	 */
	public getVenueTemplate(
templateId: number,
): Observable<Template> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/template/{template_id}',
			path: {
				template_id: templateId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Venue Template
	 * @param templateId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteVenueTemplate(
templateId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/template/{template_id}',
			path: {
				template_id: templateId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Update Venue Template
	 * @param templateId 
	 * @param requestBody 
	 * @returns Template Successful Response
	 * @throws ApiError
	 */
	public updateVenueTemplate(
templateId: number,
requestBody: TemplateUpdate,
): Observable<Template> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/template/{template_id}',
			path: {
				template_id: templateId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}