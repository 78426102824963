import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { User, UserService } from 'src/app/planvue-api';

export interface NewFavoriteDialogResult {
  user: User;
  name: string
}

export interface NewFavoriteDialogData { 
  permitUserSearch: boolean;
}

@Component({
  selector: 'app-new-favorite-list',
  standalone: true,
  imports: [...commonModules],
  templateUrl: './new-favorite-list.component.html',
  styleUrl: './new-favorite-list.component.scss'
})
export class NewFavoriteListComponent {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userService = inject(UserService);
  allowUserSearch: boolean = false;

  dialogForm: FormGroup = this.formBuilder.group({
    user: [null, [Validators.required, this.requireMatch.bind(this)]],
    name: [null, [Validators.required]],
  });

  userSearch$ = this.dialogForm.controls['user'].valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    filter(value => value && value.length >= 3),
    switchMap(value => this.userService.getUsers(1, 50, value, undefined, true)),
    map(users => users.items)
  );

  constructor(public dialog: MatDialogRef<NewFavoriteListComponent, NewFavoriteDialogResult | null>, @Inject(MAT_DIALOG_DATA) data: NewFavoriteDialogData) {
    this.allowUserSearch = data.permitUserSearch;
    if (!this.allowUserSearch) {
      this.dialogForm.removeControl('user');
    }
  }

  isConfirmDisabled() { 
    return this.dialogForm.invalid || this.dialogForm.pristine;
 }

  onDismiss(): void {
    this.dialog.close(null);
  }

  onConfirm(): void {
    this.dialog.close(this.dialogForm.value);
  }

  getName(user: User): string {
    return user ? `${user.first_name} ${user.last_name}` : '';
  }

  private requireMatch(control: FormControl): ValidationErrors | null {
    const selection = control.value;
    if (selection != null && typeof selection === 'object' && selection['id'] !== undefined) {
      return null;
    }
    return { requireMatch: true };
  }
}
