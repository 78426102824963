<h1 mat-dialog-title>
    New Favorite List
</h1>
<div mat-dialog-content>
    <p>Create a new favorite list for a selected user.</p>
    <form [formGroup]="dialogForm">
        <mat-form-field *ngIf="allowUserSearch">
            <mat-label>User</mat-label>
            <input type="text"
                    placeholder="start typing to search"
                    aria-label="Number"
                    matInput  cdkFocusInitial
                    formControlName="user"
                    [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getName">
                @for (user of userSearch$ | async; track user) {
                    <mat-option [value]="user">{{user.first_name}} {{user.last_name}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
            <mat-label>List Name</mat-label>
            <input matInput placeholder="new list name" formControlName="name">
        </mat-form-field>
        <div mat-dialog-actions>
            <button mat-button (click)="onDismiss()">Cancel</button>
            <button mat-raised-button color="primary" 
                [disabled]="isConfirmDisabled()"
                (click)="onConfirm()">Add</button>
        </div>
    </form>
</div>
