<section class="filters">
    <form [formGroup]="filterForm">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Search Locations</mat-label>
        <input matInput type="text" formControlName="searchTerm">
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Linked Status</mat-label>
        <mat-select formControlName="linked">
          <mat-option value="both">Linked & Unlinked</mat-option>
          <mat-option value="linked">Linked</mat-option>
          <mat-option value="unlinked">Unlinked</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="resetFilter()" [disabled]="!filterForm.dirty">Reset</button>
      <button  type="submit" hidden="true"></button>
    </form>
  </section>
<app-venue-location-table #table [datasource]="datasource" (viewLocationOnMapSelected)="viewLocationOnMapSelected($event)" (locationSelected)="locationSelected($event)"/>
<mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
<mat-spinner *ngIf="datasource.loading$ | async"></mat-spinner>
