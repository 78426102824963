export const $Template = {
	properties: {
		venue_name: {
	type: 'string',
	isRequired: true,
},
		venue_id: {
	type: 'number',
	isRequired: true,
},
		id: {
	type: 'number',
	isRequired: true,
},
		title: {
	type: 'string',
	isRequired: true,
},
		url: {
	type: 'string',
	isRequired: true,
},
		filename: {
	type: 'string',
	isRequired: true,
},
		format: {
	type: 'string',
	isRequired: true,
},
	},
} as const;