import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { commonModules } from 'src/app/app.config';
import { ConfirmationConfig, ConfirmationDialogComponent } from 'src/app/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { UploadComponent } from 'src/app/components/upload/upload.component';
import { ToolbarAction } from 'src/app/models/toolbar-action';
import { Venue, VenueService } from 'src/app/planvue-api';
import { VenueToolbarPageComponent } from '../../../components/base-classes/toolbarpage-component';
import { EditVenueComponent } from '../dialog/venue-dialog.component';

@Component({
  selector: 'app-venue-detail',
  templateUrl: './venue-detail.component.html',
  standalone: true,
  imports: [...commonModules, ToolbarComponent, UploadComponent],
  styleUrls: ['./venue-detail.component.scss']
})
export class VenueDetailComponent extends VenueToolbarPageComponent implements OnInit, OnDestroy {
  private readonly dialog = inject(MatDialog);

  private readonly DELETE_CONFIRMATION_CONFIG: ConfirmationConfig = {
    title: "Delete Venue",
    message: "Are you sure you want to delete this venue?",
    confirmText: "Delete",
    cancelText: "Cancel",
  };
  
  venueService = inject(VenueService);

  override ngOnInit(): void {
    super.ngOnInit();
    this.addToolbarActionToFront({ 
      id: 'edit',
      description: 'Edit Venue',
      icon: 'edit',
      title: 'Edit Venue',
      addDivider: true,
    });
    this.addToolbarActionToFront({
      id: 'delete',
      description: 'Delete Venue',
      icon: 'delete',
      title: 'Delete Venue',
    });
    this.selectedVenueService.setItem(this.route.snapshot.data['venue'] as Venue);
  }

  protected override toolbarActionClicked(action: ToolbarAction): void {
    switch (action.id) {
      case 'delete':
        this.deleteVenue(this.venue);
        break;
      case 'edit': 
        this.dialog.open(EditVenueComponent, {
          data: { venue: this.venue }
        }).afterClosed().subscribe((venue: Venue) => {
          if (venue) { 
            this.selectedVenueService.setItem(venue);
          }
        });
    }
  }

  deleteVenue(venue: Venue) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "400px",
      data: this.DELETE_CONFIRMATION_CONFIG
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.venueService.deleteVenue(venue.id!)
          .subscribe(() => {
            this.router.navigate(["/venue"]);
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.removeToolbarAction('delete');
    this.removeToolbarAction('edit');
  }
}