import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgImageSliderModule } from 'ng-image-slider-v17';
import { Observable, filter } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { DEFAULT_SUCCESS_SNACKBAR_CONFIG } from 'src/app/app.constants';
import { VenueToolbarPageComponent } from 'src/app/components/base-classes/toolbarpage-component';
import { FavoriteDialogResult, FavoriteLocationDialogComponent } from 'src/app/components/dialog/favorite-location-dialog/favorite-location-dialog.component';
import { ToolbarAction } from 'src/app/models/toolbar-action';
import { Favorite, FavoriteLocationAddMany, FavoriteService, Location, User, UserService } from 'src/app/planvue-api';
import { SelectedLocationService } from 'src/app/services/selected-location-service';
import { EditLocationDialogComponent } from '../dialog/location-dialog.component';

interface Image {
  image: string;
  thumbImage: string;
  alt?: string;
  title?: string;
  order?: number;
}

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrl: './location-detail.component.scss',
  standalone: true,
  imports: [...commonModules, NgImageSliderModule]
})
export class LocationDetailComponent extends VenueToolbarPageComponent implements OnInit, OnDestroy {
  private readonly locationEditService: SelectedLocationService = inject(SelectedLocationService);
  private readonly dialog = inject(MatDialog);
  private readonly userService = inject(UserService);
  private readonly favoriteService = inject(FavoriteService);
  private readonly snackBar = inject(MatSnackBar);

  private favoriteLists: Favorite[] = [];
  private isFavorited: boolean = false;
  private currentUser?: User;
  showDetails = false;

  location?: Location;
  images: Image[] = [];
  imageSize = { width: 300, height: 300, space: 3 };

  override ngOnInit(): void {
    super.ngOnInit();
    this.locationEditService.item.pipe(filter((location) => location != null)).subscribe((location) => {
      this.location = location!;
      this.setBreadcrumbAlias('@LocationName', this.location.name);
      this.images = location!.images.map((image) => {
        return {
          image: image.image_url,
          thumbImage: image.image_url,
          alt: '',
          title: '',
        };
      });
    });

    this.route.data.subscribe((data) => {
      this.currentUser = data['currentUser'];
      this.showDetails = this.currentUser!.role !== "user"

      this.locationEditService.setItem(data['location'] as Location);

      this.addToolbarActionToFront({
        id: 'view-location-on-map',
        icon: 'place',
        title: 'View Location on Map',
        description: 'View this location on the map.',
        addDivider: true,
      });
      this.addToolbarActionToFront({
        id: 'favorite',
        icon: 'bookmark_border',
        title: 'Add as Favorite',
        description: 'Add this location to a favorites list.',
      });
      this.addToolbarActionToFront({
        id: 'edit',
        icon: 'edit',
        title: 'Edit this Location',
        description: 'Edit this Location.',
        requiredRoles: ['admin'],
      });

      this.getFavoriteLists().subscribe((favoriteLists) => {
        this.favoriteLists = favoriteLists;
        this.updateFavoriteSelection();
      });
    });
  }

  updateFavoriteSelection() { 
    this.isFavorited = this.favoriteLists.some((favorite) => {
      if (!favorite.locations) {
        return false;
      }
      return favorite.locations.some((location) => location.id === this.location!.id);
    });

    if (this.isFavorited) {
      this.setActionColor('favorite', 'accent');
      this.setActionIcon('favorite', 'bookmark');
    } else { 
      this.setActionColor('favorite', 'primary');
      this.setActionIcon('favorite', 'bookmark_border');
    }
  }

  getFavoriteLists(): Observable<Favorite[]> {
    return this.userService.getFavorites(this.currentUser!.id!);
  }

  private addThisLocationToExistingFavoriteLists(favorites: Favorite[]) {
    const payload: FavoriteLocationAddMany = {
      favorite_ids: favorites.map((favorite) => favorite.id!),
      location_id: this.location!.id!
    };

    this.favoriteService.addLocationToFavoriteLists(payload)
      .subscribe((favorites: Favorite[]) => {
        this.favoriteLists
          .filter(list => favorites.some(favorite => favorite.id === list.id))
          .forEach((list) => {
            const locations = list.locations || [];
            locations.push({
              id: this.location!.id!,
              name: this.location!.name,
              venue_id: this.location!.venue_id
            });
            list.locations = locations;
          });
        this.updateFavoriteSelection();
        this.notify('Location added to selected favorite(s).');
    });
  }

  private removeThisLocationFromExistingFavoriteLists(favorites: Favorite[]) {
    const payload: FavoriteLocationAddMany = {
      favorite_ids: favorites.map((favorite) => favorite.id!),
      location_id: this.location!.id!
    };

    this.favoriteService.removeLocationToFavoriteLists(payload)
      .subscribe((favorites: Favorite[]) => {
        this.favoriteLists
          .filter(list => favorites.some(favorite => favorite.id === list.id))
          .forEach((list) => {
            let locations = list.locations || [];
            locations = locations.filter((location) => location.id !== this.location!.id);
            list.locations = locations;
          });
        this.updateFavoriteSelection();
        this.notify('Location removed from selected favorite(s).');
    });
  }
  

  private addThisLocationToNewFavoriteList(newFavoriteName: string) {
    this.favoriteService.createFavoriteList({
      name: newFavoriteName,
      user_id: this.currentUser!.id!,
      locations: [this.location!.id!]
    }).subscribe((favorite: Favorite) => {
      this.favoriteLists.push(favorite);
      this.updateFavoriteSelection();
      this.notify('Location added to your new favorite list.');
    });
  }

  protected override toolbarActionClicked(action: ToolbarAction): void {
    if (action.id == 'view-location-on-map') {
      this.router.navigate(['venue', this.location!.venue_id, 'map'], { queryParams: { location_id: this.location!.id } });
    } else if (action.id == 'edit') {
      this.dialog.open(EditLocationDialogComponent, {
        minWidth: "600px",
        data: { location: this.location }
      }).afterClosed().subscribe((location: Location) => {
        this.locationEditService.setItem(location);
      });
    } else if (action.id == 'favorite') {
      const removableFavorites = this.favoriteLists.filter((favorite) => favorite.locations?.some((location) => location.id === this.location!.id));
      const selectableFavorites = this.favoriteLists.filter((favorite) => removableFavorites.indexOf(favorite) === -1);

      const dialogRef = this.dialog.open(FavoriteLocationDialogComponent, {
        minWidth: "650px",
        data: {
          location: this.location,
          userFavorites: selectableFavorites,
          alreadyFavoritedLists: removableFavorites
        }
      });

      dialogRef.afterClosed().subscribe((response: FavoriteDialogResult) => {
        if (response) {
          if (response.listType === 'existing') {
            const favorites:Favorite[] = response.selectedFavorites!;
            this.addThisLocationToExistingFavoriteLists(favorites);
          } else if (response.listType === 'remove') {
              const favorites:Favorite[] = response.selectedFavorites!;
              this.removeThisLocationFromExistingFavoriteLists(favorites);
          } else { 
            this.addThisLocationToNewFavoriteList(response.newFavoriteName!);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.removeToolbarAction('view-location-on-map');
    this.removeToolbarAction('favorite');
    this.removeToolbarAction('edit');
  }


  notify(message: string) {
    this.snackBar.open(message, '', DEFAULT_SUCCESS_SNACKBAR_CONFIG);
  }
}
