import { Injectable, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subject, filter, share, shareReplay } from 'rxjs';
import { ToolbarAction } from '../models/toolbar-action';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  private actionSubject = new Subject<ToolbarAction>();
  private breadcrumbVisibleSubject = new BehaviorSubject<boolean>(true);
  private actionsSubject = new BehaviorSubject<Array<ToolbarAction>>([]);
  public actions$ = this.actionsSubject.pipe(shareReplay(1));
  private router = inject(Router);
  public actionEvents$ = this.actionSubject.pipe(share());
  public actions: Array<ToolbarAction> = [];
  public breadcrumbVisible$ = this.breadcrumbVisibleSubject.pipe(share());

  constructor() { 
    this.router.events.pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event) => {
      this.showBreadcrumb(event instanceof NavigationStart ? false : true);
    });
  }

  showBreadcrumb(show: boolean) {
    this.breadcrumbVisibleSubject.next(show);
  }

  setActions(actions: Array<ToolbarAction>) {
    this.actions = actions;
    this.actionsSubject.next(this.actions);
  }

  addPageActionToFront(action: ToolbarAction) {
    this.actions = [ action, ...this.actions, ];
    this.actionsSubject.next(this.actions);
  }

  addPageActionToBack(action: ToolbarAction) {
    this.actions = [...this.actions, action];
    this.actionsSubject.next(this.actions);
  }

  clearActions() {
    this.actions = [];
    this.actionsSubject.next(this.actions);
  }

  removeAction(id: string) {
    this.actions = this.actions.filter((a) => a.id !== id);
    this.actionsSubject.next(this.actions);
  }

  actionClicked(action: ToolbarAction) {
    this.actionSubject.next(action);
  }

  getAction(id: string): ToolbarAction | undefined {
    return this.actions.find((a) => a.id === id);
  }
}
