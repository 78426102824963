import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { UserRole } from "./planvue-api";

export const APP_TITLE: string = "Planvue";

export interface TOOLBAR_ITEM {
  title: string,
  path: string,
  required_roles: UserRole[]
}

export const TOOLBAR_ITEMS: TOOLBAR_ITEM[] = [
  { title: "Venues", path: "/venue", required_roles: ["admin", "inhouse", "user"] },
  { title: "Favorites", path: "/favorite", required_roles: ["admin", "inhouse", "user"] },
  { title: "Users", path: "/user", required_roles: ["admin"] },
];

export const PAGE_SIZE_OPTIONS: number[] = [10, 25, 50];
export const DEFAULT_PAGE_SIZE: number = PAGE_SIZE_OPTIONS[2];

export const DEFAULT_SUCCESS_SNACKBAR_CONFIG: MatSnackBarConfig = {
  duration: 3000,
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass: ['success-snackbar'],
};

export const DEFAULT_ERROR_SNACKBAR_CONFIG: MatSnackBarConfig = {
  duration: 8000,
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass: ['error-snackbar']
};
