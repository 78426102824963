
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subscription, finalize, share, shareReplay } from "rxjs";
import { DEFAULT_PAGE_SIZE } from "src/app/app.constants";
import { PageInfo } from "src/app/planvue-api";


export abstract class PagedItemsDatasource<I> implements DataSource<I> {
    public loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.pipe(shareReplay(1));
    protected itemsSubject = new BehaviorSubject<Array<I>>([]);
    protected countSubject = new BehaviorSubject<number>(0);
    public count$ = this.countSubject.asObservable();
    public items$ = this.itemsSubject.pipe(share());
    public requestItemSubscription? : Subscription;
    public pageIndex = 0;
    public pageSize = DEFAULT_PAGE_SIZE;
    public abstract columns: string[];

    constructor() { 
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    connect(collectionViewer: CollectionViewer): Observable<readonly I[]> { 
        return this.itemsSubject.asObservable();
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disconnect(collectionViewer: CollectionViewer): void {
        this.countSubject.complete();
        this.itemsSubject.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    abstract requestItems(pageIndex:number, pageSize:number): Observable<any>;

    addItem(item: I) {
        this.countSubject.next(this.countSubject.value + 1);
        this.itemsSubject.next([...this.itemsSubject.value, item]);
    }
    removeItem(item: I) {
        this.countSubject.next(this.countSubject.value - 1);
        this.itemsSubject.next(this.itemsSubject.value.filter(i => i !== item));
    }

    loadItems() {
        this.loadingSubject.next(true);
        this.itemsSubject.next([]);
        // convert UI's 0-based pageIndex to the backend's 1-based pageIndex
        this.requestItems(this.pageIndex, this.pageSize).pipe(
            finalize(() => { 
                this.loadingSubject.next(false);
            })
        ).subscribe(pagedResponse => {
            // we don't have an explicit type for the response here, but we 
            // know that our paged respones will always have an items array
            const items = pagedResponse['items'] as I[];
            const pageInfo = pagedResponse['info'] as PageInfo;
            this.countSubject.next(pageInfo.total);
            this.itemsSubject.next(items);
            this.loadingSubject.next(false);
        });
    }
}

