<h1 mat-dialog-title>Favorite</h1>
<div mat-dialog-content>
    <p class="instructions">
    @if (operation === 'existing') {
        Select the favorite list to add {{ location.name }} to.
    } @else if (operation === 'new') { 
        Provide the name of a new favorite list to add {{ this.location.name }} to.
    } @else {
        Select the list to remove {{ this.location.name }} from.
    }
    </p>
    <form [formGroup]="dialogForm">
        <mat-radio-group class="radio-group" formControlName="operationType" *ngIf="operationTypes.length > 1">
            @for (op of operationTypes; track op.value) {
                <mat-radio-button [value]="op.value" 
                    (change)="updateFormFields($event)"
                    [checked]="dialogForm.controls['operationType'].value === op.value">{{op.label}}</mat-radio-button>
            }
        </mat-radio-group>

        <mat-form-field *ngIf="operationTypes.length > 1">
            <mat-label>Favorite List</mat-label>
            <mat-select cdkFocusInitial formControlName="selectedFavorites" multiple>
                <mat-option *ngFor="let favoriteList of favorites" [value]="favoriteList">
                    {{ favoriteList.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>List Name</mat-label>
            <input matInput placeholder="New List Name" cdkFocusInitial formControlName="newListName">
        </mat-form-field>
        <div mat-dialog-actions>
            <button mat-button (click)="onDismiss()">Cancel</button>
            <button mat-raised-button color="primary"
                [disabled]="dialogForm.invalid"
                (click)="onConfirm()">{{ operation === 'remove' ? 'Remove' : 'Add' }}</button>
        </div>
    </form>
</div>