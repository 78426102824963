import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { commonModules } from 'src/app/app.config';

export interface ConfirmationConfig {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  standalone: true,
  imports: [...commonModules]
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;

  constructor(public dialog: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationConfig) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.confirmText = data.confirmText || 'Confirm';
    this.cancelText = data.cancelText || 'Cancel';
  }

  onDismiss(): void {
    this.dialog.close(false);
  }

  onConfirm(): void {
    this.dialog.close(true);
  }
}