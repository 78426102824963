import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { Venue, VenueService } from 'src/app/planvue-api';

export const venueResolver: ResolveFn<Observable<Venue | null>> = (
  route, 
  state, 
  venuesService = inject(VenueService),
  router = inject(Router),
):Observable<Venue | null> => {
    const currentRoute : ActivatedRouteSnapshot | null = route;
    const id = currentRoute.paramMap.get('venueId')!;
    return venuesService.getVenue(Number(id)).pipe(
      catchError(() => {
        router.navigate(['/venue-not-found']);
        return of(null);
    }));
};