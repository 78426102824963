
<div class="page">
    <mat-toolbar>
      <ng-template #separator>
        <mat-icon fontSet="material-icons-outlined">arrow_right</mat-icon>
      </ng-template>
      <xng-breadcrumb [separator]="separator" *ngIf="breadcrumbVisible$ | async">
        <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first; let last = last">
          <ng-container>{{ breadcrumb }}</ng-container>
        </ng-container>
      </xng-breadcrumb>      
      <span class="spacer"></span>
      <div class="buttons">
        <ng-container *ngFor="let action of actions">
          <ng-container *appRequiredRoles="action.requiredRoles">
            <button *ngIf="action.newRoute" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [color]="action.color || 'primary'" mat-icon-button
            [routerLink]="action.newRoute" 
            [matTooltip]="action.title"
            [disabled]="action.disabled">
            <mat-icon fontSet="material-icons-outlined">{{ action.icon }}</mat-icon>
            </button>
            <button *ngIf="!action.newRoute" mat-icon-button
            [matTooltip]="action.title"
            [color]="action.color || 'primary'"
            (click)="onClick(action)"
            [disabled]="action.disabled">
            <mat-icon fontSet="material-icons-outlined">{{ action.icon }}</mat-icon>
          </button>
          <mat-divider *ngIf="action.addDivider" vertical></mat-divider>
        </ng-container>
      </ng-container>
      </div>
    </mat-toolbar>
  </div>