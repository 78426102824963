<section>
    <table mat-table [dataSource]="datasource" multiTemplateDataRows>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> User </th>
            <td mat-cell *matCellDef="let favorite">{{favorite.user_first_name }} {{ favorite.user_last_name }}</td>
        </ng-container>
        <ng-container matColumnDef="list_name">
            <th mat-header-cell *matHeaderCellDef> Favorite List </th>
            <td mat-cell *matCellDef="let favorite">
                <a href="#" class="favorite-name" (click)="favoriteSpanClicked(favorite, $event)" (keyup)="favoriteSpanClicked(favorite)">
                    @if (isEditingFavoriteListName(favorite)) {
                        <mat-form-field>
                            <input matInput #favoriteListNameInput [(ngModel)]="selectedFavoriteListName" 
                                (keyup.escape)="toggleEditFavoriteListName(favorite, null)"
                                (keyup.enter)="updateFavoriteListName(favorite, null)">
                          </mat-form-field>
                    } @else {
                        {{ favorite.name }} 
                    }
                    @if (this.expandedListId === favorite.id) {
                        <button mat-icon-button *ngIf="isEditingFavoriteListName(favorite)" (click)="updateFavoriteListName(favorite, $event)"
                            [disabled]="!canUpdateFavoriteListName(favorite)">
                            <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
                        </button>
                        <button mat-icon-button (click)="toggleEditFavoriteListName(favorite, $event)">
                            <mat-icon fontSet="material-icons-outlined">{{isEditingFavoriteListName(favorite) ? 'cancel' : 'edit'}}</mat-icon>
                        </button>
                    }
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="location_count">
            <th mat-header-cell *matHeaderCellDef> Locations </th>
            <td mat-cell *matCellDef="let favorite"> {{ favorite.locations ? favorite.locations.length : 0 }} </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef aria-label="delete list">Delete List</th>
            <td mat-cell *matCellDef="let favorite" class="delete">
                <button mat-icon-button aria-label="delete favorite" (click)="deleteFavoriteList(favorite, $event)">
                    <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row expand">&nbsp;</th>
            <td mat-cell *matCellDef="let favorite" class="expand">
                <button mat-icon-button aria-label="expand row" (click)="toggleFavorite(favorite, $event)">
                    @if (expandedListId === favorite.id) {
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    } @else {
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    }
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedList">
            <td mat-cell *matCellDef="let favorite" colspan="5">
                <div class="example-element-detail"
                    [@detailExpand]="favorite.id === expandedListId ? 'expanded' : 'collapsed'">
                    <mat-chip-set aria-label="Locations" *ngIf="favorite.locations.length > 0; else no_locations">
                        @for (location of favorite.locations; track location.id) {
                        <mat-chip (click)="onLocationClick(location)">{{ location.name }}
                            <button matChipRemove aria-label="'remove ' + favorite.name"
                                (click)="removeLocationFromFavorite(favorite, location, $event)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                        }
                    </mat-chip-set>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let favorite; columns: columns;" class="favorite-row"
            (click)="toggleFavorite(favorite, $event)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedList']" class="locations-row"></tr>
        <tr class="mat-row" *matNoDataRow [hidden]="datasource.loading$ | async">
            <td class="mat-cell no-match" [attr.colspan]="columns.length">{{ getNoDataMessage() }}</td>
        </tr>
    </table>    
</section>
<ng-template #no_locations>
    <div class="no-locations">
        This favorite list is empty.
    </div>
</ng-template>