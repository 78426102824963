import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { User } from '../models/User';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class ProfileService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Get Profile
	 * Get the current user's profile
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public getMyProfile(): Observable<User> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/profile',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}