import { Component } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { commonModules } from "src/app/app.config";
import { Filter } from "src/app/datasources/favorite-datasource";
import { PageInfo } from "src/app/planvue-api";
import { PagedItemsDatasource } from "./paged-items-datasource";

@Component({
    template: '',
    standalone: true,
    imports: [...commonModules]
})
export abstract class FilteredPagedItemsDataSourceComponent<I, F extends Filter> extends PagedItemsDatasource<I> {
    public filter!: F;

    constructor() {
        super();
    }

    abstract requestItemsWithFilter(pageIndex: number, pageSize: number, filter: F): Observable<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

    override loadItems() {
        this.loadingSubject.next(true);
        this.itemsSubject.next([]);
        // convert UI's 0-based pageIndex to the backend's 1-based pageIndex
        this.requestItemsWithFilter(this.pageIndex, this.pageSize, this.filter).pipe(
            finalize(() => {
                this.loadingSubject.next(false);
            })
        ).subscribe(pagedResponse => {
            // we don't have an explicit type for the response here, but we 
            // know that our paged respones will always have an items array
            const items = pagedResponse['items'] as I[];
            const pageInfo = pagedResponse['info'] as PageInfo;
            this.countSubject.next(pageInfo.total);
            this.itemsSubject.next(items);
            this.loadingSubject.next(false);
        });
    }
}
