import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { commonModules } from 'src/app/app.config';
import { UnlinkedLocation, Venue, VenueService } from 'src/app/planvue-api';

export interface LocationDialogConfig {
  venue: Venue
}

export interface LocationDialogResult {
  selectedLocation: UnlinkedLocation | undefined;
}

@Component({
  selector: 'app-location-selection-dialog',
  templateUrl: './location-selection-dialog.component.html',
  standalone: true, 
  imports: [...commonModules],
  styleUrl: './location-selection-dialog.component.scss'
})
export class LocationSelectionDialogComponent implements OnInit {
  venue: Venue;
  venueService: VenueService = inject(VenueService);
  locations: UnlinkedLocation[] = [];
  selectedLocation: UnlinkedLocation | undefined;
  
  constructor(public dialog: MatDialogRef<LocationSelectionDialogComponent>,  @Inject(MAT_DIALOG_DATA) public config: LocationDialogConfig) {
    this.venue = config.venue;
  }
  ngOnInit(): void {
    this.venueService.getUnlinkedLocations(this.venue.id!).subscribe((locations) => { 
      this.locations = locations;
    });
  }

  onDismiss(): void {
    this.dialog.close({ selectedLocation: undefined} as LocationDialogResult );
  }

  onConfirm(): void {
    this.dialog.close({ selectedLocation: this.selectedLocation } as LocationDialogResult);
  }
}
