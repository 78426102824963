export const $User = {
	properties: {
		id: {
	type: 'any-of',
	contains: [{
	type: 'number',
}, {
	type: 'null',
}],
},
		first_name: {
	type: 'string',
	isRequired: true,
},
		last_name: {
	type: 'string',
	isRequired: true,
},
		organization: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
	isRequired: true,
},
		active: {
	type: 'boolean',
},
		venues: {
	type: 'any-of',
	contains: [{
	type: 'array',
	contains: {
		type: 'UserVenue',
	},
}, {
	type: 'null',
}],
},
		role: {
	type: 'UserRole',
	isRequired: true,
},
		email: {
	type: 'string',
	isRequired: true,
},
		created_at: {
	type: 'string',
	format: 'date-time',
},
		updated_at: {
	type: 'string',
	format: 'date-time',
},
	},
} as const;