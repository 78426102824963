<div class="container">
    <div class="details">
        <div class="inner-detail">
            <h1> {{ venue.name }} </h1>
            @if (venue.description) {
                <p> {{ venue.description }} <p>
            }
        </div>
        <div class="images">
            <div class="image-wrapper" *ngIf="venue.image_url">
                <h3>Venue Image</h3>
                <img class="venue-image" *ngIf="venue.image_url" [src]="venue.image_url" alt="venue image" />
            </div> 
            <div class="image-wrapper" *ngIf="venue.map_url">
                <h3>Venue Map</h3>
                <img class="venue-map" *ngIf="venue.map_url" [src]="venue.map_url" alt="venue map image"
                [routerLink]="['/venue', venue.id, 'map']" />
            </div>
        </div>
    </div>
</div>
