<section>
    <table mat-table [dataSource]="datasource">
        <!-- Name Column Definition -->
        <ng-container matColumnDef="venue_name">
            <th mat-header-cell *matHeaderCellDef> Venue Name </th>
            <td mat-cell *matCellDef="let template"> {{ template.venue_name }} </td>
        </ng-container>
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let template"> {{ template.title }} </td>
        </ng-container>
        <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef> Filename </th>
            <td mat-cell *matCellDef="let template">
                <a [href]="template.url" mat-button>{{ template.filename }}
                    <mat-icon iconPositionEnd>download</mat-icon>
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="delete"> 
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let template">
                <button mat-icon-button (click)="deleteTemplate(template)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="format">
            <th mat-header-cell *matHeaderCellDef> Format </th>
            <td mat-cell *matCellDef="let template"> {{ template.format }} </td>
        </ng-container>
        <tr mat-row *matRowDef="let template; columns: columns;" 
         class="venue-template-row"></tr>
        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr class="mat-row" *matNoDataRow [hidden]="datasource.loading$ | async">
            <td class="mat-cell no-match" [attr.colspan]="columns.length">{{ getNoDataMessage() }}</td>
        </tr>
    </table>
</section>