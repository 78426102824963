export const $UserUpdate = {
	properties: {
		first_name: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		last_name: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		organization: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		active: {
	type: 'any-of',
	contains: [{
	type: 'boolean',
}, {
	type: 'null',
}],
},
		role: {
	type: 'any-of',
	contains: [{
	type: 'UserRole',
}, {
	type: 'null',
}],
},
		email: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		venues: {
	type: 'any-of',
	contains: [{
	type: 'array',
	contains: {
	type: 'number',
},
}, {
	type: 'null',
}],
},
	},
} as const;