
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { FilteredPagedItemsDataSourceComponent } from "../components/base-classes/filtered-paged-items-datasource";
import { Page_Venue_, Venue, VenueService } from "../planvue-api";

export interface VenueFilter {
    searchTerm?: string;
}

export class VenuesDataSource extends FilteredPagedItemsDataSourceComponent<Venue, VenueFilter> {
    private venueService = inject(VenueService);
    public columns = ["name", "createdBy", "createdAt", "modifiedAt", "modifiedBy"];

    override requestItemsWithFilter(pageIndex: number, pageSize: number, filter: VenueFilter): Observable<Page_Venue_> {
        return this.venueService.getVenues(
            pageIndex + 1,
            pageSize,
            filter.searchTerm
        );
    }

    override requestItems(pageIndex:number, pageSize: number): Observable<Page_Venue_> {
        return this.venueService.getVenues(
            pageIndex + 1,
            pageSize
        );
    }
}
