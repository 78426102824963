import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AppPageTitleStrategy } from './app-page-title-strategy';
import { ErrorComponent } from './error/error.component';
import { canDeactivateGuard } from './guards/can-deactivate.guard';
import { FavoriteListComponent } from './pages/favorite/list/favorite-list.component';
import { EditUserComponent, NewUserComponent } from './pages/user/create-edit/user.component';
import { UserListComponent } from './pages/user/list/user-list.component';
import { LocationDetailComponent } from './pages/venue-location/detail/location-detail.component';
import { LocationListComponent } from './pages/venue-location/list/location-list.component';
import { VenueMapComponent } from './pages/venue-map/venue-map.component';
import { VenueTemplateListComponent } from './pages/venue-template/venue-template-list.component';
import { VenueBaseComponent } from './pages/venue/base/venue-base.component';
import { VenueDetailComponent } from './pages/venue/detail/venue-detail.component';
import { VenueListComponent } from './pages/venue/list/venue-list.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { currentUserResolver } from './resolvers/current-user.resolver';
import { locationResolver } from './resolvers/location.resolver';
import { userResolver } from './resolvers/user.resolver';
import { venueResolver } from './resolvers/venue.resolver';
import { VenuenotfoundComponent } from './venuenotfound/venuenotfound.component';

const routes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: 'error', component: ErrorComponent },
  { path: 'four-oh-four', component: ErrorComponent },
  { path: 'venue-not-found', component: VenuenotfoundComponent },
  {
    path: 'welcome',
    title: 'Welcome',
    component: WelcomeComponent,
    data: 
    { 
      breadcrumb: 'Home'
    }
  },
];

const catchAllRoute = { path: '**', redirectTo: '/four-oh-four' };

const venueRoutes: Routes = [
  {
    path: 'venue',
    data: {
      breadcrumb: 'Venues'
    },
    children: [
      { 
        title: 'Venue List',
        path: '',
        canActivate: [AuthGuard],
        component: VenueListComponent,
        data: {
          breadcrumb: 'Venues'
        }
      },
      {
        path: ':venueId',
        canActivate: [AuthGuard],
        component: VenueBaseComponent,
        resolve: {
          venue: venueResolver
        },
        data: {
          breadcrumb: { 
            alias: 'VenueName'
          }
        },
        children: [
          {
            path: '',
            component: VenueDetailComponent,
          },
          {
            path: 'location',
            children: [
              { 
                path: '', 
                canActivate: [AuthGuard],
                component: LocationListComponent,
                data: { 
                  breadcrumb: 'Locations'
                },
                resolve: { currentUser: currentUserResolver },
              },
              { 
                path: ':locationId', 
                component: LocationDetailComponent,
                resolve: { 
                  location: locationResolver,
                  currentUser: currentUserResolver                },
                data: { 
                  breadcrumb: { 
                    alias: 'LocationName'
                  }
                },
              },
            ]
          },
          {
            path: 'map',
            canActivate: [AuthGuard],
            component: VenueMapComponent,
            data: {
              breadcrumb: 'Map'
            }
          },
          {
            path: 'template',
            canActivate: [AuthGuard],
            component: VenueTemplateListComponent,
            resolve: { currentUser: currentUserResolver },
            data: {
              breadcrumb: 'Templates'
            },
          }
        ]
      }
    ]
  },
];

const favoritesRoutes: Routes = [
  {
    path: 'favorite',
    children: [
      {
        path: '',
        title: 'Favorites',
        component: FavoriteListComponent,
        canActivate: [AuthGuard],
        resolve: { currentUser: currentUserResolver },
        data: { 
          breadcrumb: 'Favorites'
        }
      }
    ]
  }
];

const userRoutes: Routes = [
  {
    path: 'user',
    children: [
      {
        path: '',
        title: 'User List',
        component: UserListComponent,
        canActivate: [AuthGuard],
        data: { 
          breadcrumb: 'Users'
        },
        resolve: { currentUser: currentUserResolver }
      },
      {
        path: 'new',
        title: 'Create a User',
        component: NewUserComponent,
        canActivate: [AuthGuard],
        canDeactivate: [canDeactivateGuard],
        resolve: { currentUser: currentUserResolver },
        data: {
          breadcrumb: 'New User'
        }
      },
      {
        path: ':userId',
        title: 'Edit User',
        component: EditUserComponent,
        canActivate: [AuthGuard],
        canDeactivate: [canDeactivateGuard],
        resolve: { 
          user: userResolver,
          currentUser: currentUserResolver,
         },
        data: {
          breadcrumb: 'Edit User'
        }
      }
    ]
  },
];
export const allRoutes: Routes = [...routes, ...venueRoutes, ...favoritesRoutes, ...userRoutes, catchAllRoute];

@NgModule({
  imports: [RouterModule.forRoot(allRoutes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: AppPageTitleStrategy
    }
  ]
})
export class AppRoutingModule { }
