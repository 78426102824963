import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { Location, LocationService } from 'src/app/planvue-api';



export const locationResolver: ResolveFn<Observable<Location>> = (
  route,
  state,
  locationService = inject(LocationService),
  ): Observable<Location> => {

  let currentRoute: ActivatedRouteSnapshot | null = route;
  while (currentRoute != null) {
    if (currentRoute.paramMap.has('locationId')) {
      const id = currentRoute.paramMap.get('locationId')!;
      return locationService.getLocation(Number(id));
    }
    currentRoute = currentRoute.parent;
  }
  throw new Error('No venueId found');
};
