<header>
  <mat-toolbar color="primary">
    <button mat-button routerLink="/welcome" id="homeLink"><img class="header-logo"  alt="header logo" src="/assets/images/planvue_header.png"></button>
    <span class="spacer"></span>
    <ng-container *ngIf="authService.isAuthenticated$ | async">
      <button mat-button *ngFor="let route of getToolbarPageRoutes() | async"
        routerLink="{{ route.path }}" routerLinkActive="active">{{ route.title }}</button>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User menu">
        <mat-icon [color]="isImpersonationActive() ? 'warn': ''">person</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <span mat-menu-item  *ngIf="user | async as user">
          <span>{{ user?.email }}</span>
        </span>
        <button mat-menu-item (click)="stopImpersonating()" *ngIf="isImpersonationActive()">
          <span>Stop Impersonating</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <span>Log Out</span>
        </button>
      </mat-menu>
    </ng-container>
  </mat-toolbar>
</header>

<section class="loading" *ngIf="authService.isLoading$ | async; else loaded">
  <div>
    <h2>Loading...</h2>
    <mat-spinner [color]="'accent'"></mat-spinner>
</div>
</section>

<ng-template #loaded>
  <section class="content">
    <router-outlet></router-outlet>
  </section>
</ng-template>

<footer>
  <a href="https://11thhourbiz.com/" target="_blank">
    <img id="logo" src="/assets/images/logo_white.png" alt="main planvue logo">
  </a>
  <span>Copyright &#169; {{ year }}</span>
  <span class="build-details">APP: {{sha}} ({{ref}}), API: {{api_sha}} ({{api_ref}})</span>
</footer>