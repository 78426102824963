import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { commonModules } from 'src/app/app.config';
import { FavoriteDataSource } from 'src/app/datasources/favorite-datasource';
import { Favorite, FavoriteService, Location } from 'src/app/planvue-api';
import { PagedTableComponent } from '../base-classes/paged-table';
import { ConfirmationConfig, ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-favorite-table',
  standalone: true,
  imports: [...commonModules, FormsModule],
  templateUrl: './favorite-table.component.html',
  styleUrl: './favorite-table.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FavoriteTableComponent extends PagedTableComponent<Favorite, FavoriteDataSource> implements OnInit {
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly favoriteService = inject(FavoriteService);
  private readonly dialog = inject(MatDialog);
  private readonly destroy = inject(DestroyRef);

  private readonly DELETE_FAVORITE_LIST_CONFIRMATION_CONFIG: ConfirmationConfig = {
    title: "Delete Favorite List",
    message: "",
    confirmText: "Delete",
    cancelText: "Cancel",
  };

  private readonly REMOVE_LOCATION_CONFIRMATION_CONFIG: ConfirmationConfig = {
    title: "Remove Location",
    message: "",
    confirmText: "Remove",
    cancelText: "Cancel",
  };

  selectedFavoriteListName?: string;
  expandedListId: number | null = null;

   ngOnInit(): void {
    this.columns = this.datasource.columns;
    const listId = this.route.snapshot.queryParamMap.get('listId');
    this.expandedListId = listId ? parseInt(listId) : null;
  }

  getNoDataMessage():string {
    return 'No favorite lists found.';
  }

  toggleFavorite(favorite: Favorite, event:MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.expandedListId = this.expandedListId === favorite.id ? null : favorite.id;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        listId: this.expandedListId ? this.expandedListId: null
      }
    });

    this.selectedFavoriteListName = undefined;
  }

  deleteFavoriteList(favorite: Favorite, event:MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
        
    this.DELETE_FAVORITE_LIST_CONFIRMATION_CONFIG.message = `Are you sure you want to delete '${favorite.name}'?`;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: "400px",
      data: this.DELETE_FAVORITE_LIST_CONFIRMATION_CONFIG
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.favoriteService.deleteFavoriteList(favorite.id).subscribe(() => {
          this.notifySuccess('Favorite list was deleted.');
          this.datasource.loadItems();
        });
      }
    });
  }

  onLocationClick(location: Location) {
    this.router.navigate(['/venue',location.venue_id, 'location', location.id]);
}

  removeLocationFromFavorite(favorite: Favorite, location:Location, event:MouseEvent): void { 
    event.preventDefault();
    event.stopPropagation();
    
    this.REMOVE_LOCATION_CONFIRMATION_CONFIG.message = `Are you sure you want to remove '${location.name}' from this list?`;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: "400px",
      data: this.REMOVE_LOCATION_CONFIRMATION_CONFIG
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.favoriteService.removeLocationFromFavoriteList(favorite.id, location.id!).subscribe(() => {
          favorite.locations = favorite.locations!.filter(l => l.id !== location.id);
          this.notifySuccess('Location was removed from this list.');
        });
      }
    });
  }

  favoriteSpanClicked(favorite: Favorite, event?:MouseEvent): void {
    if (this.isEditingFavoriteListName(favorite)) {
      if (event) {
        event.preventDefault(); 
        event.stopPropagation();
      }
    }
  }

  toggleEditFavoriteListName(favorite: Favorite, event:MouseEvent | null): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.selectedFavoriteListName === undefined) {
      this.selectedFavoriteListName = favorite.name;
    } else {
      this.selectedFavoriteListName = undefined;
    }
  }

  isEditingFavoriteListName(favorite: Favorite):boolean {
    if (this.expandedListId === favorite.id) {
      return this.selectedFavoriteListName !== undefined;
    }

    return false;
  }

  canUpdateFavoriteListName(favorite: Favorite):boolean {
    return this.selectedFavoriteListName !== undefined && this.selectedFavoriteListName !== favorite.name;
  }

  updateFavoriteListName(favorite: Favorite, event:MouseEvent | null): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.selectedFavoriteListName === undefined) {
      return;
    }

    this.favoriteService.updateFavoriteList(favorite.id, {name: this.selectedFavoriteListName!})
    .pipe(
      takeUntilDestroyed(this.destroy)
    ).subscribe((updatedFavorite:Favorite) => {
      this.selectedFavoriteListName = undefined;
      this.notifySuccess('Favorite list name updated.');
      favorite.name = updatedFavorite.name!;
    });
  }
}
