import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, of, startWith, switchMap } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { NewFavoriteDialogResult, NewFavoriteListComponent } from 'src/app/components/dialog/new-favorite-list/new-favorite-list.component';
import { FavoriteTableComponent } from 'src/app/components/favorite-table/favorite-table.component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { FavoriteDataSource, FavoriteFilter } from 'src/app/datasources/favorite-datasource';
import { ToolbarAction } from 'src/app/models/toolbar-action';
import { Favorite, FavoriteService, User } from 'src/app/planvue-api';
import { FilteredPagedToolbarComponent } from '../../../components/base-classes/filtered-paged-toolbar-component';


@Component({
  selector: 'app-favorite-list',
  standalone: true,
  imports: [...commonModules, ToolbarComponent, FavoriteTableComponent],
  templateUrl: './favorite-list.component.html',
  styleUrl: './favorite-list.component.scss'
})
export class FavoriteListComponent extends FilteredPagedToolbarComponent<Favorite, FavoriteFilter, FavoriteDataSource> implements OnInit {
  private readonly formBuidler = inject(FormBuilder);
  protected readonly favoriteService = inject(FavoriteService);
  private readonly dialog = inject(MatDialog);
  protected override datasource = new FavoriteDataSource();
  filterForm:FormGroup = this.formBuidler.group({
    searchTerm: [],
  });
  searchTerm$ = this.filterForm.get('searchTerm')!.valueChanges.pipe(debounceTime(250));
  currentUser?: User;

  constructor() {
    super();
  }

  resetFilter(): void {
    this.filterForm.setValue({ searchTerm: '' });
    this.filterForm.markAsPristine();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.currentUser = this.route.snapshot.data['currentUser'] as User;
    if (this.currentUser!.role === 'user' || this.currentUser!.role === 'inhouse') {
      this.datasource.columns = this.datasource.columns.filter(column => column !== 'user');
    }

    this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value)
    ).subscribe((value) => {
      this.filterValue$.next({ searchTerm: value.searchTerm });
    });

    this.clearToolbarActions();
    this.addToolbarActionToFront(
      {
        id: "newFavorite",
        title: 'New Favorite',
        description: 'Create a new favorite list.',
        icon: 'add',
      }
    );
  }

  protected override toolbarActionClicked(action: ToolbarAction): void {
    if (action.id === 'newFavorite') {
      const dialogRef = this.dialog.open(NewFavoriteListComponent, {
        maxWidth: "300px",
        data: { permitUserSearch: this.currentUser!.role === "admin" || this.currentUser!.role === "inhouse" }
      });

      dialogRef.afterClosed().pipe(
        switchMap((result: NewFavoriteDialogResult) => {
          if (result) {
            return this.favoriteService.createFavoriteList({
              name: result.name,
              user_id: result.user ? result.user.id : this.currentUser!.id,
            });
          }
          return of(null);
        })
      ).subscribe((favorite: Favorite | null) => {
        if (favorite) {
          this.notifySuccess(`The new favorite list '${favorite.name}' was created successfully.`);
          this.datasource.loadItems();
        }
      });
    }
  }
}
