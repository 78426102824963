<section>
    <table mat-table [dataSource]="datasource">
        <!-- Name Column Definition -->
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef> First Name </th>
            <td mat-cell *matCellDef="let user"> {{ user.first_name }} </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef> Last Name </th>
            <td mat-cell *matCellDef="let user"> {{ user.last_name }} </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Emaill </th>
            <td mat-cell *matCellDef="let user"> {{ user.email }} </td>
        </ng-container>
        <ng-container matColumnDef="org">
            <th mat-header-cell *matHeaderCellDef> Organization </th>
            <td mat-cell *matCellDef="let user"> {{ user.organization }} </td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef> Role </th>
            <td mat-cell *matCellDef="let user"> {{ (user.role.charAt(0).toUpperCase() + user.role.slice(1)) }} </td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let user">
                <mat-icon *ngIf="user.active" fontSet="material-icons-outlined">check_circle</mat-icon>
                <mat-icon *ngIf="!user.active" fontSet="material-icons-outlined">circle</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="impersonate">
            <th mat-header-cell *matHeaderCellDef> Impersonate </th>
            <td mat-cell *matCellDef="let user" (click)="impersonateUser(user)">
                <mat-icon fontSet="material-icons-outlined">switch_account</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let user; columns: columns;" (click)="selectUser(user)" class="user-row"></tr>
        <tr class="mat-row" *matNoDataRow [hidden]="datasource.loading$ | async">
            <td class="mat-cell no-match" [attr.colspan]="columns.length">{{ getNoDataMessage() }}</td>
        </tr>
    </table>
</section>