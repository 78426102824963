import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Favorite } from '../models/Favorite';
import type { FavoriteAdd } from '../models/FavoriteAdd';
import type { FavoriteLocationAdd } from '../models/FavoriteLocationAdd';
import type { FavoriteLocationAddMany } from '../models/FavoriteLocationAddMany';
import type { FavoriteUpdate } from '../models/FavoriteUpdate';
import type { Page_Favorite_ } from '../models/Page_Favorite_';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class FavoriteService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Get Favorite Lists
	 * Retrieves a page of favorite items.
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * user (AuthenticatedUser): The authenticated user.
 * page (int, optional): The page number to retrieve. Defaults to 1.
 * page_size (int, optional): The number of items per page. Defaults to 25.
 * 
 * Returns:
 * Page[Favorite]: A page object containing the favorite items.
	 * @param page 
	 * @param pageSize 
	 * @param searchTerm 
	 * @returns Page_Favorite_ Successful Response
	 * @throws ApiError
	 */
	public getFavoriteLists(
page: number = 1,
pageSize: number = 25,
searchTerm?: string,
): Observable<Page_Favorite_> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/favorite',
			query: {
				page, page_size: pageSize, search_term: searchTerm
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Create Favorite List
	 * Create a a favorite list for the current user
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * user (AuthenticatedUser): The authenticated user.
 * favorite (Favorite): The favorite item to add.
 * 
 * Returns:
 * Favorite: The added favorite item.
	 * @param requestBody 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public createFavoriteList(
requestBody: FavoriteAdd,
): Observable<Favorite> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/favorite',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Update Favorite List
	 * Updates a favorite list.
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * favorite_id (int): The ID of the favorite.
 * update (FavoriteAdd): The updated favorite object.
 * 
 * Returns:
 * Favorite: The updated favorite object.
 * 
 * Raises:
 * NoRecordFoundException: If no favorite is found with the given ID.
	 * @param favoriteId 
	 * @param requestBody 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public updateFavoriteList(
favoriteId: number,
requestBody: FavoriteUpdate,
): Observable<Favorite> {
				return this.httpRequest.request({
			method: 'PATCH',
			url: '/favorite/{favorite_id}',
			path: {
				favorite_id: favoriteId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Favorite List
	 * @param favoriteId 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public getFavoriteList(
favoriteId: number,
): Observable<Favorite> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/favorite/{favorite_id}',
			path: {
				favorite_id: favoriteId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Location To Favorite List
	 * Adds a location to a favorite.
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * favorite_id (int): The ID of the favorite.
 * update (FavoriteLocationAdd): Model containing the ID of the location to be added.
 * 
 * Returns:
 * Favorite: The updated favorite object.
 * 
 * Raises:
 * NoRecordFoundException: If no favorite is found with the given ID or no location is found with the given ID.
	 * @param favoriteId 
	 * @param requestBody 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public addLocationToFavoriteList(
favoriteId: number,
requestBody: FavoriteLocationAdd,
): Observable<Favorite> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/favorite/{favorite_id}',
			path: {
				favorite_id: favoriteId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Favorite List
	 * Deletes a favorite list.
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * favorite_id (int): The ID of the favorite.
 * 
 * Returns:
 * Favorite: The updated favorite object.
 * 
 * Raises:
 * NoRecordFoundException: If no favorite is found with the given ID or no location is found with the given ID.
	 * @param favoriteId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteFavoriteList(
favoriteId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/favorite/{favorite_id}',
			path: {
				favorite_id: favoriteId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Location To Favorite Lists
	 * Adds a location to a favorite.
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * update (FavoriteLocationAdd): Model containing the ID of the location to be added.
 * 
 * Returns:
 * Favorite: The updated favorite object.
 * 
 * Raises:
 * NoRecordFoundException: If no favorite is found with the given ID or no location is found with the given ID.
	 * @param requestBody 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public addLocationToFavoriteLists(
requestBody: FavoriteLocationAddMany,
): Observable<Array<Favorite>> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/favorite/add-location-to-many',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Location From Favorite Lists
	 * Adds a location to a favorite.
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * update (FavoriteLocationAdd): Model containing the ID of the location to be added.
 * 
 * Returns:
 * Favorite: The updated favorite object.
 * 
 * Raises:
 * NoRecordFoundException: If no favorite is found with the given ID or no location is found with the given ID.
	 * @param requestBody 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public removeLocationToFavoriteLists(
requestBody: FavoriteLocationAddMany,
): Observable<Array<Favorite>> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/favorite/delete-location-from-many',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Remove Location From Favorite List
	 * Removes a location from a favorite.
 * 
 * Args:
 * favorite_service (FavoriteService): The favorite service instance.
 * favorite_id (int): The ID of the favorite.
 * location_id (int): The ID of the location.
 * 
 * Returns:
 * Favorite: The updated favorite object.
 * 
 * Raises:
 * NoRecordFoundException: If no favorite is found with the given ID or no location is found with the given ID.
	 * @param favoriteId 
	 * @param locationId 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public removeLocationFromFavoriteList(
favoriteId: number,
locationId: number,
): Observable<Favorite> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/favorite/{favorite_id}/{location_id}',
			path: {
				favorite_id: favoriteId, location_id: locationId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}