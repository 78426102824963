import { NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AngularHttpRequest } from './core/AngularHttpRequest';
import { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { OpenAPI } from './core/OpenAPI';

import { FavoriteService } from './services/FavoriteService';
import { HealthcheckService } from './services/HealthcheckService';
import { ImageService } from './services/ImageService';
import { LocationService } from './services/LocationService';
import { ProfileService } from './services/ProfileService';
import { UserService } from './services/UserService';
import { VenueService } from './services/VenueService';
import { VenueTemplateService } from './services/VenueTemplateService';

@NgModule({
	imports: [HttpClientModule],
	providers: [
		{
			provide: OpenAPI,
			useValue: {
				BASE: OpenAPI?.BASE ?? '',
				VERSION: OpenAPI?.VERSION ?? '0.1.0',
				WITH_CREDENTIALS: OpenAPI?.WITH_CREDENTIALS ?? false,
				CREDENTIALS: OpenAPI?.CREDENTIALS ?? 'include',
				TOKEN: OpenAPI?.TOKEN,
				USERNAME: OpenAPI?.USERNAME,
				PASSWORD: OpenAPI?.PASSWORD,
				HEADERS: OpenAPI?.HEADERS,
				ENCODE_PATH: OpenAPI?.ENCODE_PATH,
			} as OpenAPIConfig,
		},
		{
			provide: BaseHttpRequest,
			useClass: AngularHttpRequest,
		},
		FavoriteService,
		HealthcheckService,
		ImageService,
		LocationService,
		ProfileService,
		UserService,
		VenueService,
		VenueTemplateService,
	]
})
export class Planvue {}
