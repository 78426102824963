import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { commonModules } from "src/app/app.config";
import { BaseFormComponent } from "src/app/components/base-classes/base-form-component";

export interface LocationForm { 
  name: FormControl<string | null>;
  description: FormControl<string | null>;
  bleed: FormControl<string | null>;
  dimensions: FormControl<string | null>;
  suggested_material_generic: FormControl<string | null>;
  suggested_material_specific: FormControl<string | null>;
  designers_note: FormControl<string | null>;
}


@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrl: './location-form.component.scss',
  standalone: true, 
  imports: [...commonModules],
})
export class LocationFormComponent extends BaseFormComponent<LocationForm> {
  
}
