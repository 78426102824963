import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

export class ImpersonationHttpInterceptor implements HttpInterceptor {
    protected impersonated_user: string | null = null;

    constructor() { 
      this.impersonated_user = localStorage.getItem('Planvue-Impersonated-User');
    }
    
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.impersonated_user) {
        req = req.clone({
          headers: req.headers.set('X-Impersonate-User', this.impersonated_user)
        });
      }
      return next.handle(req);
    }
  }