<section class="grid">
  <mat-card *ngFor="let venue of venues" class="venue">
    <mat-card-header>
      <mat-card-title>{{ venue.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <a class="image" appBackgroundImage 
        [routerLink]="['/venue', venue.id]"
        [url]="getImage(venue)">
      </a>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button [disabled]="!venue.map_url"[routerLink]="['/venue',venue.id,'map']">Venue Map</button>
      <button mat-raised-button [routerLink]="['/venue',venue.id,'location']">Locations</button>
      <button mat-raised-button [routerLink]="['/venue',venue.id,'template']">Templates</button>
    </mat-card-actions>
  </mat-card>
</section>