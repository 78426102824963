import { DOCUMENT } from '@angular/common';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
import { commonModules } from './app.config';
import { APP_TITLE, TOOLBAR_ITEM, TOOLBAR_ITEMS } from './app.constants';
import { HealthcheckService, User } from './planvue-api';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [...commonModules]
})
export class AppComponent implements OnInit {
  authService = inject(AuthService);
  profileService = inject(ProfileService);
  apiHealthService = inject(HealthcheckService);
  breadcrumbService = inject(BreadcrumbService);
  user: Subject<User> = this.profileService.user$;
  sha = environment.sha;
  ref = environment.ref;
  api_ref? : string
  api_sha? : string

  title = APP_TITLE;
  year = new Date().getFullYear();
  constructor(@Inject(DOCUMENT) public document: Document){
    
  }
  ngOnInit(): void {
    this.apiHealthService.getApiHealthcheck().subscribe((response) => { 
      this.api_ref = response.ref;
      this.api_sha = response.sha;
    })
  }


  stopImpersonating(): void {
    this.profileService.stopImpersonating();
  }

  isImpersonationActive(): boolean { 
    return this.profileService.isImpersonating();
  }

  getToolbarPageRoutes() : Observable<TOOLBAR_ITEM[]> {
     return this.user.pipe(map((user: User) => { 
      return TOOLBAR_ITEMS.filter(item => {
        if (item.required_roles === undefined) {
          return true;
        }

        if (user === undefined || user.role === undefined) {
          return false;
        }

        return item.required_roles.includes(user.role);
      });
    }));
  }

  login() {
    this.authService.loginWithRedirect();
  }

  logout() {
    this.authService.logout({ 
      logoutParams: { 
        returnTo: document.location.origin 
      } 
    });
  }
}
