import { DestroyRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subscription } from 'rxjs';
import { ProfileService } from '../services/profile.service';

@Directive({
  selector: '[appRequiredRoles]',
  standalone: true
})
export class RequiredRolesDirective implements OnInit {
  private readonly profileService: ProfileService = inject(ProfileService);
  private readonly templateRef = inject(TemplateRef);
  private readonly viewContainer = inject(ViewContainerRef);
  private roles?: string[];
  private destroy = inject(DestroyRef);

  @Input()
  set appRequiredRoles(roles: string[] | undefined) {
    this.roles = roles;
  }

  userSubscription?: Subscription;

  constructor() { 

  }

  ngOnInit(): void {
    this.profileService.user$.pipe(
      takeUntilDestroyed(this.destroy)
    ).subscribe((user) => {
      if (this.roles === undefined || this.roles.length === 0 || this.roles.includes(user.role)) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
