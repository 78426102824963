import { Component, DestroyRef, Inject, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { UploadComponent, UploadType } from 'src/app/components/upload/upload.component';
import { Image, ImageService, Venue, VenueAdd, VenueService, VenueUpdate } from 'src/app/planvue-api';
import { VenueForm, VenueFormComponent } from './venue-form.component';

interface EditVenueDialogData {
  venue: Venue
}

@Component({
  template: '',
})
export abstract class BaseVenueFormComponent<X>  {
  protected readonly destroy = inject(DestroyRef);
  protected readonly dialog = inject(MatDialog);
  protected readonly venueService = inject(VenueService);
  protected readonly imageService = inject(ImageService);
  imageUploadedSubject: Subject<string> = new Subject<string>();
  mapUploadedSubject: Subject<string> = new Subject<string>();
  venueImageUrl?: string
  mapImageUrl?: string
  venueImage?: Image
  venueMap?: Image
  readonly uploadFileType: UploadType = UploadType.IMAGE;
  protected venue!: X
  protected abstract saveLabel: string
  protected abstract dialogTitle: string;

  @ViewChild("mapUpload")
  mapUpload?: UploadComponent;

  @ViewChild("imageUpload")
  imageUpload?: UploadComponent;

  @ViewChild("stepper")
  stepper?: MatStepper;

  @ViewChild("detailsStep")
  detailsStep?: MatStep

  @ViewChild("imageStep")
  imageStep?: MatStep

  @ViewChild("mapStep")
  mapStep?: MatStep

  @ViewChild(VenueFormComponent)
  venueFormComponent?: VenueFormComponent

  venueForm =  new FormGroup<VenueForm>({
      description: new FormControl<string | null>(null),
      name: new FormControl<string | null>(null, {validators: [Validators.required] }),
  })

  constructor(protected dialogref: MatDialogRef<BaseVenueFormComponent<X>>) {
    this.dialogref = dialogref;
  }

  abstract save(): void

  onImageSelected(file: File): void {
    this.imageService.uploadImage({
      uploaded_file: file
    }).subscribe((image) => {
      this.imageUpload?.uploadComplete()
      this.venueImage = image
      this.venueImageUrl = image.url
      this.imageStep!.completed = true;
    });
  }

  onMapSelected(file: File): void {
    this.imageService.uploadImage({
      uploaded_file: file
    }).subscribe((image) => {
      this.mapUpload?.uploadComplete()
      this.venueMap = image;
      this.mapImageUrl = image.url
      this.mapStep!.completed = true;
    });
  }

  cancel() : void { 
    this.dialogref.close();
  }
}

@Component({
  selector: 'app-new-venue',
  templateUrl: './venue-dialog.component.html',
  standalone: true,
  imports: [...commonModules, VenueFormComponent, UploadComponent],
  styleUrls: ['./venue-dialog.component.scss']
})
export class NewVenueComponent extends BaseVenueFormComponent<VenueAdd> {
  protected saveLabel: string = "Create"
  protected dialogTitle: string = "Create a new Venue"
  
  constructor(dialogref: MatDialogRef<NewVenueComponent>) {
    super(dialogref)
    this.dialogref = dialogref;
  }

  save(): void {
    const venue_add = { ... this.venueForm.value, 
      image_id:this.venueImage?.id ?? undefined, 
      map_image_id: this.venueMap?.id ?? undefined,
    } as VenueAdd
    this.venueService.addVenue(venue_add).subscribe((venue) => {
      this.dialogref.close(venue)
    })
  }
}
interface EditVenueDialogData {
  venue: Venue
}

@Component({
  selector: 'app-edit-venue',
  templateUrl: './venue-dialog.component.html',
  standalone: true,
  imports: [...commonModules, VenueFormComponent, UploadComponent],
  styleUrls: ['./venue-dialog.component.scss']
})
export class EditVenueComponent extends BaseVenueFormComponent<VenueUpdate> {
  protected venue_id: number
  protected saveLabel: string = "Save"
  protected dialogTitle: string

  constructor(dialogref: MatDialogRef<EditVenueComponent>, @Inject(MAT_DIALOG_DATA) data: EditVenueDialogData) {
    super(dialogref)
    this.dialogTitle = `Edit ${data.venue.name}`
    this.venue_id = data.venue.id!
    this.venue = {
      name: data.venue.name,
      description: data.venue.description,
    }
    this.venueForm.patchValue(this.venue)
    this.mapImageUrl = data.venue.map_url ?? undefined
    this.venueImageUrl = data.venue.image_url ?? undefined
  }

  override save(): void {
    const venue_add = 
    { ...this.venueForm.value,
      image_id: this.venueImage?.id ?? undefined,
      map_image_id: this.venueMap?.id ?? undefined
    } as VenueAdd
    this.venueService.updateVenue(this.venue_id, venue_add).subscribe((venue) => {
      this.dialogref.close(venue)
    })
  }


}