<app-toolbar />
<section class="filters">
    <form [formGroup]="filterForm">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>Search Users</mat-label>
            <input matInput type="text" formControlName="searchTerm">
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role">
                <mat-option value="all">All</mat-option>
                <mat-option value="admin">Admin</mat-option>
                <mat-option value="inhouse">Inhouse</mat-option>
                <mat-option value="user">User</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>User Status</mat-label>
            <mat-select formControlName="status">
                <mat-option value="active">Active</mat-option>
                <mat-option value="inactive">Inactive</mat-option>
                <mat-option value="both">Both</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="resetFilter()" [disabled]="!filterForm.dirty">Reset</button>
        <button type="submit" hidden="true"></button>
    </form>
</section>
<app-user-table [datasource]="datasource" (userSelected)="userSelected($event)"
    (impersonateUserSelected)="impersonateUser($event)" />
<mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
<mat-spinner *ngIf="datasource.loading$ | async"></mat-spinner>