import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable, Subscription, debounceTime, filter, map, startWith } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from 'src/app/app.constants';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { VenueFilter, VenuesDataSource } from 'src/app/datasources/venues-datasource';
import { ToolbarAction } from 'src/app/models/toolbar-action';
import { ProfileService } from 'src/app/services/profile.service';
import { ToolbarPageComponent } from '../../../components/base-classes/toolbarpage-component';
import { VenueGridComponent } from '../../../components/venue-list-grid/venue-list-grid.component';
import { NewVenueComponent } from '../dialog/venue-dialog.component';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  standalone: true,
  imports: [...commonModules, VenueGridComponent, ToolbarComponent],
  styleUrls: ['./venue-list.component.scss']
})
export class VenueListComponent extends ToolbarPageComponent implements OnInit, OnDestroy {
  private formBuidler = inject(FormBuilder);
  private profileService = inject(ProfileService);
  public venueFilter$!:Observable<VenueFilter>;
  datasource = new VenuesDataSource();
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  venueFilterSubscription?:Subscription;
  dialog = inject(MatDialog);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  filterForm:FormGroup = this.formBuidler.group({
    searchTerm: [],
  });

  searchTerm$ = this.filterForm.get('searchTerm')!.valueChanges.pipe(debounceTime(250));

  filterValue$:Observable<VenueFilter> = this.searchTerm$
    .pipe(
      startWith(''),
      map(searchTerm => ({searchTerm: searchTerm} as VenueFilter)),
    );

  filterValues$:BehaviorSubject<VenueFilter> = new BehaviorSubject({searchTerm: ''} as VenueFilter);

  override ngOnInit(): void {
    super.ngOnInit();
    this.paginator.pageIndex = Number(this.route.snapshot.queryParams['page']) || 0;
    this.paginator.pageSize = this.route.snapshot.queryParams['pageSize'] || DEFAULT_PAGE_SIZE;
    this.datasource.count$.subscribe(count => {
      this.paginator!.length = count;
    });

    this.filterValue$.subscribe((filter) => {
      this.datasource.pageIndex = 0;
      this.datasource.filter = filter;
      this.datasource.loadItems();
    });

    this.datasource.loadItems();

    this.paginator.page.subscribe((page: PageEvent) => {
      this.datasource.pageIndex = page.pageIndex;
      this.datasource.pageSize = page.pageSize;
      this.router.navigate([], { queryParams: { page: page.pageIndex, pageSize: page.pageSize } });
      this.datasource.loadItems();
    });

    this.setToolbarActions([
      {
        id: "new-venue",
        title: 'New Venue',
        description: 'Create a new venue.',
        icon: 'add',
        requiredRoles: ['admin'],
      },
 
    ]);
  }

  ngOnDestroy(): void {
    this.removeToolbarAction('newVenue');
  }
  
  protected override toolbarActionClicked(action: ToolbarAction): void {
    if (action.id == 'new-venue') {
      const dialogRef = this.dialog.open(NewVenueComponent, {
        minWidth: "700px",
        maxWidth: "1000px",
        minHeight: "600px",
      });

      dialogRef.afterClosed().pipe(filter((venue) => venue)).subscribe(() => { 
        this.datasource.loadItems();
      });
    } 
  }


  resetFilter():void {
    this.filterForm.setValue({searchTerm: ''});
    this.filterForm.markAsPristine();
  }
}
