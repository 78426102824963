import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { commonModules } from 'src/app/app.config';
import { AuthButtonComponent } from 'src/app/components/auth-button/auth-button.component';

@Component({
  selector: 'app-landing',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  standalone: true,
  imports: [...commonModules, AuthButtonComponent]
})
export class WelcomeComponent {
  auth = inject(AuthService);
}
