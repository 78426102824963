import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, combineLatest, debounceTime, startWith } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { FilteredPagedToolbarComponent } from 'src/app/components/base-classes/filtered-paged-toolbar-component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { UserTableComponent } from 'src/app/components/user-table/user-table.component';
import { UserFilter, UsersDataSource } from 'src/app/datasources/users-datasource';
import { ToolbarAction } from 'src/app/models/toolbar-action';
import { User } from 'src/app/planvue-api';
import { ProfileService } from 'src/app/services/profile.service';
  @Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    standalone: true, 
    imports: [...commonModules, UserListComponent, UserTableComponent, ToolbarComponent],
    styleUrl: './user-list.component.scss'
  })
  export class UserListComponent extends FilteredPagedToolbarComponent<User, UserFilter, UsersDataSource> implements OnInit, OnDestroy {
    private formBuidler = inject(FormBuilder);
    private user!: User;
    profileService: ProfileService = inject(ProfileService);
    actionEventSubscripton?: Subscription;
    datasource: UsersDataSource = new UsersDataSource();

    filterForm: FormGroup = this.formBuidler.group({
      searchTerm: [''],
      role: ['all'],
      status: ['both']
    });

    searchField$ = this.filterForm.get('searchTerm')!.valueChanges.pipe(
      startWith(''),
      debounceTime(250));
    roleSelector$ = this.filterForm.get('role')!.valueChanges.pipe(startWith('all'));
    statusSelector$ = this.filterForm.get('status')!.valueChanges.pipe(startWith('both')); 
    
    filterChangedSubscription = combineLatest([this.searchField$, this.roleSelector$, this.statusSelector$])
      .subscribe(([searchTerm, role, status]) => {
        this.filterValue$.next(new UserFilter(searchTerm, role, status));
    });

    resetFilter(): void {
      this.filterForm.setValue(new UserFilter('', 'all', 'both'));
      this.filterForm.markAsPristine();
    }
    
    override ngOnInit(): void {
      super.ngOnInit();
      this.user = this.route.snapshot.data['currentUser'] as User;
      if (this.user.role == 'admin') {
        this.datasource.columns.push("impersonate");
      }
      this.setToolbarActions([
        {
          id: "newUser",
          title: 'New User',
          description: 'Create a new user.',
          icon: 'add',
        }
      ]);
    }

    ngOnDestroy(): void {
      this.removeToolbarAction('newUser');
      if (this.filterChangedSubscription) {
        this.filterChangedSubscription.unsubscribe();
      }
    }

    userSelected(user: User) { 
      this.router.navigate(['/user', user.id!.toString()]);
    }

    impersonateUser(user: User) {
      if (user !== undefined && user.email) {
        this.profileService.impersonateUser(user.email);
      }
    }
  
    protected override toolbarActionClicked(action: ToolbarAction): void {
      if (action.id == 'newUser') {
        this.router.navigate(['user', 'new']);
      }
    }
  }

