import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Body_upload_image } from '../models/Body_upload_image';
import type { Image } from '../models/Image';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class ImageService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Get Image
	 * @param imageId 
	 * @returns Image Successful Response
	 * @throws ApiError
	 */
	public getImage(
imageId: number,
): Observable<Image> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/image/{image_id}',
			path: {
				image_id: imageId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete Image
	 * @param imageId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteImage(
imageId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/image/{image_id}',
			path: {
				image_id: imageId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Upload Image
	 * @param formData 
	 * @returns Image Successful Response
	 * @throws ApiError
	 */
	public uploadImage(
formData: Body_upload_image,
): Observable<Image> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/image',
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}