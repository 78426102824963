import { Injectable, inject } from '@angular/core';
import { Venue, VenueService } from '../planvue-api';
import { SelectedItemService } from './selected-item.service';
@Injectable({
  providedIn: 'root'
})
export class SelectedVenueService extends SelectedItemService<Venue, VenueService>{
  protected override service: VenueService = inject(VenueService);
  private id?: number;
  load(id: number) {
    this.id = id;
    this.service.getVenue(id).subscribe((venue) => {
      this.setItem(venue);
    });
  }
}

