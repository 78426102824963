import { Component, DestroyRef, NgZone, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { Feature, Position } from 'geojson';
import * as L from 'leaflet';
import 'leaflet-draw';
import { Subject, debounceTime, filter } from 'rxjs';
import { commonModules } from 'src/app/app.config';
import { LocationDialogResult, LocationSelectionDialogComponent } from 'src/app/components/dialog/location-selection-dialog/location-selection-dialog.component';
import { Area, VenueMap, VenueService } from 'src/app/planvue-api';
import { ProfileService } from 'src/app/services/profile.service';
import { VenueToolbarPageComponent } from '../../components/base-classes/toolbarpage-component';

export interface MapLayerClickEvent {
  area: Area;
}

interface MapMovement { 
  center: string
  bounds: string
}

@Component({
  selector: 'app-venue-map',
  standalone: true,
  templateUrl: './venue-map.component.html',
  imports: [...commonModules, LeafletModule, LocationSelectionDialogComponent],
  styleUrl: './venue-map.component.scss'
})
export class VenueMapComponent extends VenueToolbarPageComponent implements OnInit {
  public venueMap!: VenueMap;
  private map!: L.Map;
  private ngZone: NgZone = inject(NgZone);
  private bounds?: L.LatLngBounds;
  private center?: L.LatLng;
  private venueService: VenueService = inject(VenueService);
  private layerFeatureMap: Map<L.Layer, Feature> = new Map();
  private locationIdLayerMap: Map<number, L.Polygon> = new Map();
  private geoJsonLayer!: L.GeoJSON;
  private dialog: MatDialog = inject(MatDialog);
  private disableLayerClicks: boolean = false;
  private profileService = inject(ProfileService);
  private mapZoomSubject = new Subject<MapMovement>();
  private mapMoveSubject = new Subject<MapMovement>();
  private destoryRef = inject(DestroyRef)
  
  public options: L.MapOptions = {
    minZoom: -10,
    crs: L.CRS.Simple,
    layers: [
    ],
    center: L.latLng(0, 0),
    zoom: -3,
  };

  boundsToString(bounds: L.LatLngBounds): string {
    return bounds.getSouthWest().lat + ',' + bounds.getSouthWest().lng + ',' + bounds.getNorthEast().lat + ',' + bounds.getNorthEast().lng;
  }
  boundsFromString(bounds: string): L.LatLngBounds {
    return L.latLngBounds([parseFloat(bounds.split(',')[0]), parseFloat(bounds.split(',')[1])],
      [parseFloat(bounds.split(',')[2]), parseFloat(bounds.split(',')[3])]);
  }

  centerToString(center: L.LatLng): string {
    return center.lat + ',' + center.lng;
  }

  centerFromString(center: string): L.LatLng {
    return L.latLng(parseFloat(center.split(',')[0]), parseFloat(center.split(',')[1]));
  }

  featureToArea(feature: Feature): Area {
    if (feature.geometry.type == "Point") {
      return {
        id: 0,
        location_id: 0,
        location_name: "",
        type: "circle",
        coordinates: [feature.geometry.coordinates[0], feature.geometry.coordinates[1], feature.properties!['radius']]
      };
    } else if (feature.geometry.type == "Polygon") {
      return {
        id: 0,
        location_id: 0,
        location_name: "",
        type: "polygon",
        coordinates: feature.geometry.coordinates[0].flat().map((x: number) => Math.round(x)) as number[],
      };
    } else {
      throw new Error("Unknown geometry type: " + feature.geometry.type);
    }
  }


  areaToFeature(area: Area): Feature {
    if (area.type == "circle") {
      return {
        type: "Feature",
        properties: {
          area: area,
          radius: area.coordinates[2]
        },
        geometry: {
          type: "Point",
          coordinates: [area.coordinates[0], area.coordinates[1]]
        }
      };
    } else if (area.type == "rect") {
      return {
        type: "Feature",
        properties: {
          area: area
        },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [area.coordinates[0], area.coordinates[1]],
              [area.coordinates[2], area.coordinates[3]],
              [area.coordinates[4], area.coordinates[5]],
              [area.coordinates[6], area.coordinates[7]],
              [area.coordinates[8], area.coordinates[9]]
            ]
          ]
        },
      };
    }
    else if (area.type == "polygon") {
      if (area.coordinates.length % 2 != 0) {
        throw new Error("Invalid number of coordinates for polygon, must be even");
      }
      const points: Position[][] = [[], []];
      for (let i = 0; i < area.coordinates.length; i += 2) {
        points[0].push([area.coordinates[i], area.coordinates[i + 1]]);
      }
      return {
        type: "Feature",
        properties: {
          area: area
        },
        geometry: {
          type: "Polygon",
          coordinates: points,
        },
      };
    } else {
      throw new Error("Unknown area type: " + area.type);
    }
  }
  areaClicked(area: Area) {
    this.ngZone.run(() => {
      if (this.disableLayerClicks == false) {
        this.router.navigate(['venue', this.venue.id, 'location', area.location_id]);
      }
    });
  }

  addLeafletClickHandler(layer: L.Layer, area: Area) {
    layer.on('click', (e: L.LeafletMouseEvent) => { // eslint-disable-line @typescript-eslint/no-unused-vars
      this.areaClicked(area);
    });
  }

  onMapZoom(e: L.LeafletEvent) { // eslint-disable-line @typescript-eslint/no-unused-vars
    this.bounds = this.map.getBounds();
    if (this.bounds && this.center) {
      this.mapZoomSubject.next({ 
        center: this.centerToString(this.map.getCenter()),
        bounds: this.boundsToString(this.map.getBounds())
      });
    }
  }
  

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMapMove(e: L.LeafletEvent) {
    this.center = this.map.getCenter();
    if (this.bounds && this.center) {
      this.mapZoomSubject.next({ 
        center: this.centerToString(this.map.getCenter()),
        bounds: this.boundsToString(this.map.getBounds())
      });
    }
    
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drawEvent(e: any) {
    if (e.type == L.Draw.Event.DRAWSTART || e.type == L.Draw.Event.EDITSTART || e.type == L.Draw.Event.DELETESTART) {
      this.disableLayerClicks = true;
    } else if (e.type == L.Draw.Event.DRAWSTOP || e.type == L.Draw.Event.EDITSTOP || e.type == L.Draw.Event.DELETESTOP) {
      this.disableLayerClicks = false;
    }
    if (e.type == L.Draw.Event.CREATED) {
      const feature = e.layer.toGeoJSON();
      this.addAndLinkArea(this.featureToArea(feature));
    } else if (e.type == L.Draw.Event.EDITED) {
      e.layers.eachLayer((layer: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        const new_feature = layer.toGeoJSON();
        const existing_feature = this.layerFeatureMap.get(layer);
        if (existing_feature) {
          const area = existing_feature.properties!['area'] as Area;
          area.coordinates = this.featureToArea(new_feature).coordinates;
          this.updateArea(area);
        }
      });
    } else if (e.type == L.Draw.Event.DELETED) {
      e.layers.eachLayer((layer: L.Layer) => {
        const feature = this.layerFeatureMap.get(layer);
        if (feature) {
          const area = feature.properties!['area'] as Area;
          this.layerFeatureMap.delete(layer);
          this.deleteArea(area);
        }
      });
    }
  }

  addAndLinkArea(area: Area) {
    this.ngZone.run(() => {
      const x = this.dialog.open(LocationSelectionDialogComponent, {
        data: { venue: this.venue },
        width: '750px',
      });
      x.afterClosed().subscribe((results: LocationDialogResult) => {
        if (results && results.selectedLocation) {
          this.venueService.addMapArea(this.venue.id!, {
            location_id: results.selectedLocation.id!,
            type: area.type,
            coordinates: area.coordinates
          }).subscribe((area) => {
            this.geoJsonLayer.addData(this.areaToFeature(area));
            this.notifySuccess(`The area has been linked with ${results.selectedLocation?.name || 'the location'}`)
          });
        }
      });
    });
  }

  deleteArea(area: Area) {
    this.venueService.deleteMapArea(this.venue.id!, area.id!).subscribe(() => {
      this.notifySuccess("The requested area has been deleted");
    });
  }

  updateArea(area: Area) {
    this.ngZone.run(() => {
      this.venueService.updateMapArea(this.venue.id!, area.id!, {
        location_id: area.location_id,
        type: area.type,
        coordinates: area.coordinates
      }).subscribe(() => {
        this.notifySuccess("The requested area has been updated");
      });

    });
  }
  onMapReady(map: L.Map) {
    this.map = map;

    this.geoJsonLayer = L.geoJSON([], {
      onEachFeature: (feature, layer) => {
        const area = feature.properties!['area'] as Area;
        this.layerFeatureMap.set(layer, feature);
        if (area.location_id)
          this.locationIdLayerMap.set(area.location_id, layer as L.Polygon);
        this.addLeafletClickHandler(layer, area);
      },
      style: function (feature) {
        return {
          color: feature!.properties.color || 'red',
          radius: feature!.properties.radius,
          fillColor: feature!.properties.fillColor || 'green',
          fillOpacity: 0
        };
      }
    });

    this.map.addLayer(this.geoJsonLayer);

    map.on(L.Draw.Event.DELETESTART, this.drawEvent, this);
    map.on(L.Draw.Event.DELETESTOP, this.drawEvent, this);
    map.on(L.Draw.Event.DRAWSTART, this.drawEvent, this);
    map.on(L.Draw.Event.DRAWSTOP, this.drawEvent, this);
    map.on(L.Draw.Event.EDITSTART, this.drawEvent, this);
    map.on(L.Draw.Event.EDITSTOP, this.drawEvent, this);
    map.on(L.Draw.Event.CREATED, this.drawEvent, this);
    map.on(L.Draw.Event.EDITED, this.drawEvent, this);
    map.on(L.Draw.Event.DELETED, this.drawEvent, this);

    this.profileService.user$.pipe(filter((user) => user != null && user.role == 'admin'),takeUntilDestroyed(this.destoryRef)).subscribe(() => { 
      this.map.addControl(new L.Control.Draw({
        draw: {
          polyline: false,
          polygon: { shapeOptions: { color: 'green' } },
          rectangle: <any>{ showArea: false, shapeOptions: { color: 'blue' } }, // eslint-disable-line @typescript-eslint/no-explicit-any
          circlemarker: false,
          circle: false,
          marker: false
        },
        edit: {
          featureGroup: this.geoJsonLayer,
          remove: true
        }
      }));
    });

    this.selectedVenueService.item.pipe(takeUntilDestroyed(this.destroy)).subscribe((venue) => {
      this.venue = venue!;
      this.venueService.getVenueMap(this.venue.id!).subscribe((venueMap) => {
        this.venueMap = venueMap;
        this.map.addLayer(
          new L.ImageOverlay(this.venueMap.map_url,
            L.latLngBounds([0, 0], [venueMap.map_height, venueMap.map_width]),)
        );
        this.venueMap.areas.forEach((area: Area) => {
          this.geoJsonLayer.addData(this.areaToFeature(area));
        });

        if (params['location_id']) {
          const layer = this.locationIdLayerMap.get(Number(params['location_id']));
          const feature = this.layerFeatureMap.get(layer!);
          feature!.properties!['fillColor'] = 'yellow';
          feature!.properties!['color'] = 'red';
          this.geoJsonLayer.resetStyle(layer!);
          if (layer) {
            this.map.flyTo(layer.getCenter(), 1, { animate: true, duration: .75});
          }
        }
        
      });
      const params = this.route.snapshot.queryParams;
      if (params['center'] && !params['location_id']) {
        const center = this.centerFromString(params['center']);
        this.map.setView(center, this.map.getZoom());
      }

      if (params['bounds'] && !params['location_id']) {
        const bounds = this.boundsFromString(params['bounds']);
        this.map.fitBounds(bounds);
      }
    });
  }

  override ngOnInit(): void {
    this.mapZoomSubject.pipe(debounceTime(250), takeUntilDestroyed(this.destoryRef)).subscribe(() => { 
      this.router.navigate([], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
        queryParams: {
          center: this.centerToString(this.map.getCenter()),
          bounds: this.boundsToString(this.map.getBounds())
        }
      });
    }); 
    this.mapMoveSubject.pipe(debounceTime(250), takeUntilDestroyed(this.destoryRef)).subscribe(() => { 
      this.router.navigate([], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
        queryParams: {
          center: this.centerToString(this.map.getCenter()),
          bounds: this.boundsToString(this.map.getBounds())
        }
      });
    });
  }
}

