import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { commonModules } from 'src/app/app.config';
import { Template } from 'src/app/planvue-api';
import { VenueTemplatesDataSource } from '../../datasources/venue-templates-datasource';
import { PagedTableComponent } from '../base-classes/paged-table';
import { ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-venue-template-table',
  templateUrl: './venue-template-table.component.html',
  styleUrl: './venue-template-table.component.scss',
  standalone: true, 
  imports: [...commonModules]
})
export class VenueTemplateTableComponent extends PagedTableComponent<Template, VenueTemplatesDataSource> implements OnInit {
  @Output()
  public readonly templateDeleted = new EventEmitter<Template>();
  dialog = inject(MatDialog);

  ngOnInit(): void {
    this.columns = this.datasource.columns;
  }

  deleteTemplate(template: Template): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: "400px",
      data:  {
        title: `Delete Template ${template.filename}?`,
        message: "",
        confirmText: "Delete",
        cancelText: "Cancel",
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
          this.templateDeleted.emit(template);
      }
    });
  }

  getNoDataMessage():string {
    return 'No Venue Templates found.';
  }
}
