import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

import { ApplicationConfig, ErrorHandler, NgZone, importProvidersFrom, inject } from '@angular/core';
import { provideRouter } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { allRoutes } from './app-routing.module';
import { RequiredRolesDirective } from './directives/required-roles.directive';
import { ErrorInterceptor } from './interceptors/error-http-interceptor';
import { ImpersonationHttpInterceptor } from './interceptors/impersonation-http-interceptor';
import { OpenAPI, OpenAPIConfig, Planvue } from './planvue-api';



export const commonModules = [
  CommonModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatToolbarModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatButtonToggleModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatTooltipModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,
  BreadcrumbComponent,
  BreadcrumbItemDirective,
  RequiredRolesDirective
];

OpenAPI.BASE = environment.apiBaseUrl ?? OpenAPI.BASE;

class PlanvueErrorHandler implements ErrorHandler { 
  sentryHandler = Sentry.createErrorHandler({ showDialog: true });
  zone = inject(NgZone);
  router = inject(Router);
  constructor() {
  }
  
  handleError(error: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
      this.zone.run(() => 
        this.router.navigate(['/error'], { state: { error: error } })
      );
      this.sentryHandler.handleError(error);
  }
}


export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      Planvue,
      AuthModule.forRoot(environment.auth),
    ]),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ImpersonationHttpInterceptor, multi: true },
    {
        provide: ErrorHandler,
        useClass: PlanvueErrorHandler,
    },
    {
      provide: OpenAPI,
      useValue: {
        BASE: environment.apiBaseUrl ?? OpenAPI?.BASE,
        VERSION: OpenAPI?.VERSION ?? '0.1.0',
        WITH_CREDENTIALS: OpenAPI?.WITH_CREDENTIALS ?? false,
        CREDENTIALS: OpenAPI?.CREDENTIALS ?? 'include',
        TOKEN: OpenAPI?.TOKEN,
        USERNAME: OpenAPI?.USERNAME,
        PASSWORD: OpenAPI?.PASSWORD,
        HEADERS: OpenAPI?.HEADERS,
        ENCODE_PATH: OpenAPI?.ENCODE_PATH,
      } as OpenAPIConfig,
    },
    provideRouter(allRoutes),
    provideAnimations()
  ]
};
