import { enableProdMode } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular-ivy";
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry_dsn,
  environment: environment.sentry_environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ["api.planvue.local", "api.dev.planvue.halo3.net"],
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
