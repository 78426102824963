import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, catchError } from 'rxjs';
import { ApiError, User, UserService } from 'src/app/planvue-api';

export const userResolver: ResolveFn<Observable<User>> = (
  route, 
  state, 
  userService = inject(UserService)
):Observable<User> => {
    const id = route.paramMap.get('userId')!;
    return userService.getUser(Number(id)).pipe(catchError((err: ApiError, req: Observable<User>) => { 

      if (err.status == 404) { 
        // Handle 404 error
      }

      return req;
    }));
};
