<img src="/assets/images/planvue.png" class="logo" alt="Planvue Logo">
<p>Welcome to Plan VUE, the web based tool that combines event planning,
  branding and floorplans all into a Visual Understanding Environment (VUE)
  to assist you in bringing your event to life...</p>

@if((auth.isAuthenticated$ | async) === false) {
<p class="loginnote">Note: All users who had an account prior to August 3rd, 2024, you will need to reset your password prior to logging into this new version of PlanVue. 
  First, Click on the Logon button below and then click the "Forgot Password" link. You will be emailed instructions to reset your password and login to the application.
</p>
}
<app-auth-button></app-auth-button>