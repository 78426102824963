<div mat-dialog-title>{{dialogTitle}}</div>
<div class="content">
    <mat-stepper linear="true" #stepper>
        <mat-step #detailsStep label="Venue Details" [completed]="venueForm.valid">
            
            <app-venue-form mat-dialog-content [formGroup]="venueForm"></app-venue-form>
            <div mat-dialog-actions class="btn-panel">
                <button mat-raised-button (click)="cancel()">Cancel</button>
                <button mat-raised-button color="primary" [disabled]="!detailsStep.completed"
                    matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step #imageStep label="Venue Image" [completed]="venueImageUrl">
            <div mat-dialog-content class="image-upload">
                <img class="venue-image" [src]="venueImageUrl || '/assets/images/no_image.jpg'" alt="venue map image" />
                <app-upload #imageUpload [showImagePreview]="false" [uploadEvents]="imageUploadedSubject.asObservable()"
                    (fileSelected)="onImageSelected($event)" [uploadType]="uploadFileType" />
            </div>
            <div mat-dialog-actions class="btn-panel">
                <button mat-raised-button (click)="cancel()">Cancel</button>
                <button mat-raised-button color="primary" [disabled]="!imageStep.completed" matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step #mapStep label="Venue Map" [completed]="mapImageUrl">
            <div mat-dialog-content class="image-upload">
                <img class="venue-map" [src]="mapImageUrl || '/assets/images/no_image.jpg'" alt="venue map image" />
                <app-upload #mapUpload [showImagePreview]="false" [uploadEvents]="mapUploadedSubject.asObservable()"
                    (fileSelected)="onMapSelected($event)" [uploadType]="uploadFileType" />
                <div mat-dialog-actions class="btn-panel">
                    <button mat-raised-button (click)="cancel()">Cancel</button>
                    <button mat-raised-button color="primary" (click)="save()"
                        [disabled]="!mapStep.completed">Save</button>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
</div>