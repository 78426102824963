import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { commonModules } from "src/app/app.config";
import { BaseFormComponent } from "src/app/components/base-classes/base-form-component";

export interface VenueForm { 
  name: FormControl<string | null>;
  description: FormControl<string | null>;
}

@Component({
  selector: 'app-venue-form',
  templateUrl: './venue-form.component.html',
  styleUrl: './venue-form.component.scss',
  standalone: true, 
  imports: [...commonModules],
})
export class VenueFormComponent extends BaseFormComponent<VenueForm> { 

} 