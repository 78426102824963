import { BehaviorSubject, shareReplay } from 'rxjs';

export abstract class SelectedItemService<T, X> {
  private itemSubject = new BehaviorSubject<T | null>(null);
  public item = this.itemSubject.pipe(shareReplay(1));
  protected abstract service:X

  setItem(item: T) {
    this.itemSubject.next(item);
  }

  public abstract load(id: number): void;
}
