import { inject } from "@angular/core";
import { User } from "@auth0/auth0-angular";
import { Observable } from "rxjs";
import { Favorite, FavoriteService, Page_Favorite_ } from "src/app/planvue-api";
import { FilteredPagedItemsDataSourceComponent } from "../components/base-classes/filtered-paged-items-datasource";

export interface Filter {
    searchTerm?: string;
}

export interface FavoriteFilter extends Filter {
}

export class FavoriteDataSource extends FilteredPagedItemsDataSourceComponent<Favorite, FavoriteFilter> {
    private readonly favoriteService: FavoriteService = inject(FavoriteService);
    public columns = ["user", "list_name", "location_count", "delete", "expand"];
    user?: User;

    constructor() {
        super();
    }

    override requestItemsWithFilter(pageIndex: number, pageSize: number, filter: FavoriteFilter): Observable<Page_Favorite_> {
        return this.favoriteService.getFavoriteLists(
            pageIndex + 1,
            pageSize,
            filter.searchTerm
        );
    }

    override requestItems(pageIndex: number, pageSize: number): Observable<Page_Favorite_> {
        return this.favoriteService.getFavoriteLists(
            pageIndex + 1,
            pageSize
        );
    }
}