import { Component, OnInit, inject } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, FormGroupDirective } from "@angular/forms";

export interface LocationForm { 
  name: FormControl<string | null>;
  description: FormControl<string | null>;
  bleed: FormControl<string | null>;
  dimensions: FormControl<string | null>;
  suggested_material_generic: FormControl<string | null>;
  suggested_material_specific: FormControl<string | null>;
  designers_note: FormControl<string | null>;
}

@Component({
    template: ''
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class BaseFormComponent<X extends { [K in keyof X]: AbstractControl<any, any>; }> implements OnInit {
  formGroupDirective = inject(FormGroupDirective)
  formGroup! : FormGroup<X>
  
  ngOnInit(): void { 
    this.formGroup = this.formGroupDirective.control
  }
}
