import { Component, DestroyRef, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { filter } from "rxjs";
import { Venue } from "src/app/planvue-api";
import { SelectedVenueService } from "src/app/services/selected-venue-service";
import { BreadcrumbService } from "xng-breadcrumb";
import { commonModules } from "../../app.config";
import { ToolbarAction } from "../../models/toolbar-action";
import { ToolbarService } from "../../services/toolbar.service";
import { BaseComponent } from "./base-component";




@Component({
    template: '',
    standalone: true,
    imports: [...commonModules]
})
export class ToolbarPageComponent extends BaseComponent implements OnInit {
    private toolbarService: ToolbarService = inject(ToolbarService);
    private breadcrumbService = inject(BreadcrumbService);
    protected destroy: DestroyRef = inject(DestroyRef);
    protected route  = inject(ActivatedRoute);
    protected router = inject(Router);

    public ngOnInit(): void {
        this.toolbarService.actionEvents$.pipe(takeUntilDestroyed(this.destroy)).subscribe((action) => {
            this.toolbarActionClicked(action);
        });
    }

    public setBreadcrumbAlias(alias: string, value: string) {
        this.breadcrumbService.set(alias, value);
    }

    public setToolbarActions(actions: ToolbarAction[]) {
        this.toolbarService.setActions(actions);
    }

    public clearToolbarActions() {
        this.toolbarService.clearActions();
    }

    public removeToolbarAction(actionId: string) {
        this.toolbarService.removeAction(actionId);
    }

    public addToolbarActionToFront(action: ToolbarAction) {
        this.toolbarService.addPageActionToFront(action);
    }

    protected toolbarActionClicked(action: ToolbarAction) { // eslint-disable-line @typescript-eslint/no-unused-vars
    }

    protected setActionColor(id: string, color: string) {
        const action = this.toolbarService.getAction(id);
        if (action) {
            action.color = color;
        }
    }

    protected setActionIcon(id: string, icon: string) {
        const action = this.toolbarService.getAction(id);
        if (action) {
            action.icon = icon;
        }
    }

    protected getAction(actionId: string) { 
        return this.toolbarService.getAction(actionId);
    }

    protected updateAction(actionId: string, action: ToolbarAction) {
        const actionToUpdate = this.toolbarService.getAction(actionId);
        if (actionToUpdate) {
            actionToUpdate.title = action.title;
            actionToUpdate.description = action.description;
            actionToUpdate.icon = action.icon;
            actionToUpdate.color = action.color;
            actionToUpdate.newRoute = action.newRoute;
            actionToUpdate.disabled = action.disabled;
        }
    }

}

@Component({
    template: '',
    standalone: true,
    imports: [...commonModules]
})
export class VenueToolbarPageComponent extends ToolbarPageComponent implements OnInit {
    protected venue!: Venue;
    protected selectedVenueService = inject(SelectedVenueService);
    
    override ngOnInit(): void {
      super.ngOnInit();
      this.selectedVenueService.item.pipe(
        takeUntilDestroyed(this.destroy),
        filter((venue) => venue !== null)).subscribe((venue) => {
          this.venue = venue!;
      });
      const venueFromResolver = this.route.snapshot.data['venue'] as Venue;
      if (venueFromResolver) {
        this.selectedVenueService.setItem(this.venue);
      }
    }

}
