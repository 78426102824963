<h1 mat-dialog-title>
    Link Location
</h1>
<div mat-dialog-content>
    <p>
        <mat-form-field>
            <mat-label>Select Location</mat-label>
            <mat-select [(value)]="selectedLocation">
                @for (location of locations; track location) {
                    <mat-option [value]="location">{{location.name}} - {{ location.description }}</mat-option>
                }
                </mat-select>
        </mat-form-field>
    </p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Cancel</button>
    <button cdkFocusInitial mat-raised-button color="primary" (click)="onConfirm()">Link Location</button>
</div>