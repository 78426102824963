import { Component } from '@angular/core';
import { commonModules } from '../app.config';

@Component({
  selector: 'app-venuenotfound',
  standalone: true,
  imports: [...commonModules],
  templateUrl: './venuenotfound.component.html',
  styleUrl: './venuenotfound.component.scss'
})
export class VenuenotfoundComponent {

}
