import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from 'src/app/app.constants';
import { VenueToolbarPageComponent as SelectedVenueToolbarPageComponent, ToolbarPageComponent } from 'src/app/components/base-classes/toolbarpage-component';
import { PagedItemsDatasource } from './paged-items-datasource';

@Component({
  selector: 'app-paged-toolbar',
  template: '',
})
export abstract class PagedToolbarComponent<I, X extends PagedItemsDatasource<I>> extends ToolbarPageComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  protected abstract datasource: X;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.paginator.pageIndex = Number(this.route.snapshot.queryParams['page']) || 0;
    this.paginator.pageSize = this.route.snapshot.queryParams['pageSize'] || DEFAULT_PAGE_SIZE;
    this.datasource.count$.subscribe(count => {
      this.paginator!.length = count;
    });

    this.paginator.page.subscribe((page: PageEvent) => {
      this.datasource.pageIndex = page.pageIndex;
      this.datasource.pageSize = page.pageSize;
      this.router.navigate([], { queryParams: { page: page.pageIndex, pageSize: page.pageSize } });
      this.datasource.loadItems();
    });
  }
}

@Component({
  selector: 'app-venue-paged-toolbar',
  template: '',
})
export abstract class PagedToolbarComponentWithSelectedVenueComponent<I, X extends PagedItemsDatasource<I>> extends SelectedVenueToolbarPageComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  protected abstract datasource: X;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.paginator.pageIndex = Number(this.route.snapshot.queryParams['page']) || 0;
    this.paginator.pageSize = this.route.snapshot.queryParams['pageSize'] || DEFAULT_PAGE_SIZE;
    this.datasource.count$.subscribe(count => {
      this.paginator!.length = count;
    });

    this.paginator.page.subscribe((page: PageEvent) => {
      this.datasource.pageIndex = page.pageIndex;
      this.datasource.pageSize = page.pageSize;
      this.router.navigate([], { queryParams: { page: page.pageIndex, pageSize: page.pageSize } });
      this.datasource.loadItems();
    });
  }
}