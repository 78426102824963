export const $Location = {
	properties: {
		id: {
	type: 'any-of',
	contains: [{
	type: 'number',
}, {
	type: 'null',
}],
},
		name: {
	type: 'string',
	isRequired: true,
},
		venue_id: {
	type: 'number',
	isRequired: true,
},
		area_id: {
	type: 'any-of',
	contains: [{
	type: 'number',
}, {
	type: 'null',
}],
},
		description: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		suggested_material_specific: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
	isRequired: true,
},
		suggested_material_generic: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
	isRequired: true,
},
		dimensions: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
	isRequired: true,
},
		bleed: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
	isRequired: true,
},
		images: {
	type: 'array',
	contains: {
		type: 'LocationImage',
	},
	isRequired: true,
},
		designers_note: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
	isRequired: true,
},
		friendly_id: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		created_at: {
	type: 'string',
	format: 'date-time',
},
		updated_at: {
	type: 'string',
	format: 'date-time',
},
		created_by: {
	type: 'any-of',
	contains: [{
	type: 'User',
}, {
	type: 'null',
}],
},
		updated_by: {
	type: 'any-of',
	contains: [{
	type: 'User',
}, {
	type: 'null',
}],
},
		is_linked: {
	type: 'boolean',
	isReadOnly: true,
	isRequired: true,
},
		image_count: {
	type: 'number',
	isReadOnly: true,
	isRequired: true,
},
	},
} as const;