export const $PageInfo = {
	properties: {
		start: {
	type: 'number',
	isRequired: true,
},
		end: {
	type: 'number',
	isRequired: true,
},
		total: {
	type: 'number',
	isRequired: true,
},
		page: {
	type: 'number',
	isRequired: true,
},
		num_pages: {
	type: 'number',
	isRequired: true,
},
		page_size: {
	type: 'number',
	isRequired: true,
},
	},
} as const;