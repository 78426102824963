import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Favorite } from '../models/Favorite';
import type { Page_User_ } from '../models/Page_User_';
import type { User } from '../models/User';
import type { UserAdd } from '../models/UserAdd';
import type { UserUpdate } from '../models/UserUpdate';
import { BaseHttpRequest } from '../core/BaseHttpRequest';



@Injectable({
  providedIn: 'root',
})
export class UserService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * Conflict Check
	 * @param email 
	 * @returns void Successful Response
	 * @throws ApiError
	 */
	public checkEmailExists(
email: string,
): Observable<void> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/user/verify',
			query: {
				email
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Users
	 * @param page 
	 * @param pageSize 
	 * @param search 
	 * @param role 
	 * @param active 
	 * @returns Page_User_ Successful Response
	 * @throws ApiError
	 */
	public getUsers(
page: number = 1,
pageSize: number = 25,
search?: unknown,
role?: unknown,
active?: unknown,
): Observable<Page_User_> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/user',
			query: {
				page, page_size: pageSize, search, role, active
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get User
	 * @param userId 
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public getUser(
userId: number,
): Observable<User> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/user/{user_id}',
			path: {
				user_id: userId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Delete User
	 * @param userId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public deleteUser(
userId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/user/{user_id}',
			path: {
				user_id: userId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Update User
	 * @param userId 
	 * @param requestBody 
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public updateUser(
userId: number,
requestBody: UserUpdate,
): Observable<User> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/user/{user_id}',
			path: {
				user_id: userId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add User
	 * @param requestBody 
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public addUser(
requestBody: UserAdd,
): Observable<User> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/user/',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Set Venues
	 * @param userId 
	 * @param requestBody 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public setVenues(
userId: number,
requestBody: Array<number>,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'PUT',
			url: '/user/{user_id}/venue',
			path: {
				user_id: userId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Add Venue
	 * @param userId 
	 * @param venueId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public addUserToVenue(
userId: number,
venueId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/user/{user_id}/venue',
			path: {
				user_id: userId
			},
			query: {
				venue_id: venueId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Remove Venue
	 * @param userId 
	 * @param venueId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public removeUserFromVenue(
userId: number,
venueId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'DELETE',
			url: '/user/{user_id}/venue/{venue_id}',
			path: {
				user_id: userId, venue_id: venueId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Get Favorites
	 * @param userId 
	 * @returns Favorite Successful Response
	 * @throws ApiError
	 */
	public getFavorites(
userId: number,
): Observable<Array<Favorite>> {
				return this.httpRequest.request({
			method: 'GET',
			url: '/user/{user_id}/favorites',
			path: {
				user_id: userId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

	/**
	 * Reset Password
	 * Send a password reset email to the specified user.
	 * @param userId 
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public sendPasswordReset(
userId: number,
): Observable<unknown> {
				return this.httpRequest.request({
			method: 'POST',
			url: '/user/{user_id}/send_password_reset',
			path: {
				user_id: userId
			},
			errors: {
				403: `No Permission`,
				404: `Record Not Found`,
				409: `Record Already Exists`,
				422: `Validation Error`,
				500: `Internal Server Error`,
				502: `API Service is not currently online?`,
			},
		});
	}

}