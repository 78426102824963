export const $VenueAdd = {
	properties: {
		name: {
	type: 'string',
	isRequired: true,
},
		description: {
	type: 'any-of',
	contains: [{
	type: 'string',
}, {
	type: 'null',
}],
},
		image_id: {
	type: 'number',
},
		map_image_id: {
	type: 'number',
},
	},
} as const;