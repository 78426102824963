
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { Page_User_, User, UserService } from "src/app/planvue-api";
import { FilteredPagedItemsDataSourceComponent } from "../components/base-classes/filtered-paged-items-datasource";

export class UserFilter {
    searchTerm?: string;
    role?: string;
    status?: string;

    constructor(searchTerm:string, role:string, status:string) {
        this.searchTerm = searchTerm;
        this.role = role;
        this.status = status;
    }

    getSearchTerm(): string | undefined {
        return this.searchTerm;
    }

    getRole(): string | undefined {
        return this.role === 'all' ? undefined : this.role;
    }

    getStatus(): boolean | undefined {
        return this.status=== 'both' ? undefined : this.status === 'active';
    }
}

export class UsersDataSource extends FilteredPagedItemsDataSourceComponent<User, UserFilter> {
    private userService = inject(UserService);
    public columns = ["firstName", "lastName", "email", "org", "role", "active"];

    constructor() {
        super();
    }

    override requestItemsWithFilter(pageIndex: number, pageSize: number, filter: UserFilter): Observable<Page_User_> {
        return this.userService.getUsers(
            pageIndex + 1, 
            pageSize,
            filter.getSearchTerm(),
            filter.getRole(),
            filter.getStatus()
        );
    }

    override requestItems(pageIndex:number, pageSize: number): Observable<Page_User_> {
        return this.userService.getUsers(pageIndex + 1, pageSize);
    }
}
