import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { commonModules } from 'src/app/app.config';

export enum UploadType {
  IMAGE = "image/*",
  ZIP = ".zip",
  AUDIO = "audio/*",
  VIDEO = "video/*",
  PDF = ".pdf"
}

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [...commonModules],
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss'
})
/**
 * Represents a component for uploading images.
 */
export class UploadComponent implements OnInit {
  @Input({required: true})
  uploadType: UploadType = UploadType.IMAGE;

  @Input()
  fileUrl?: string;

  @Input() 
  uploadEvents?: Observable<string | null>;

  @Input()
  showImagePreview: boolean = true;

  @Input()
  uploadText: string = 'Choose file';

  @ViewChild("fileUpload")
  inputField: ElementRef<HTMLInputElement> | undefined;

  @Output() 
  fileSelected = new EventEmitter<File>();
  destroy = inject(DestroyRef);

  uploading:boolean = false;

  uploadFilename?: string;

  ngOnInit(): void {
    if (this.uploadEvents) {
        this.uploadEvents.pipe(takeUntilDestroyed(this.destroy)).subscribe((uploadedFileUrl) => {
        this.fileUrl = uploadedFileUrl ? uploadedFileUrl : undefined;
        this.uploadFilename = undefined;
        this.uploading = false;
      });
    }
  }

  // Make the UploadType enum available to the template.
  public get UploadType() {
    return UploadType; 
  }

  getFilename(): string | undefined {
    if (this.fileUrl) {
      return this.fileUrl.split('/').pop();
    } else if (this.uploadFilename) {
      return 'Uploading ' + this.uploadFilename;
    }

    return undefined;
  }

  /**
   * Event handler for when a file is selected.
   * @param event - The file selection event.
   */
  selectFile(event: Event) {
    const input = event.target as HTMLInputElement;
    const file: File = input.files![0];
    if (file) {
      this.uploading = true;
      this.uploadFilename = file.name;
      this.fileSelected.emit(file);
    }
  }

  uploadComplete() { 
    this.uploading = false;
    this.fileUrl = undefined;
    this.uploadFilename = undefined;
    this.inputField!.nativeElement.value = '';
  }

  /**
   * Clears the image URL.
   */
  clear() {
    this.fileUrl = undefined;
  }
}
