<app-toolbar></app-toolbar>
<section class="filters">
  <form [formGroup]="filterForm">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Search Venues</mat-label>
      <input matInput type="text" formControlName="searchTerm">
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="resetFilter()" [disabled]="!filterForm.dirty">Reset</button>
    <button  type="submit" hidden="true"></button>
  </form>
</section>
<app-venue-grid [datasource]="datasource"></app-venue-grid>
<mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
<mat-spinner *ngIf="datasource.loading$ | async"></mat-spinner>



