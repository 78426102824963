export const $Favorite = {
	properties: {
		id: {
	type: 'number',
	isRequired: true,
},
		name: {
	type: 'string',
	isRequired: true,
},
		user_first_name: {
	type: 'string',
	isRequired: true,
},
		user_last_name: {
	type: 'string',
	isRequired: true,
},
		user_id: {
	type: 'number',
	isRequired: true,
},
		locations: {
	type: 'array',
	contains: {
		type: 'FavoriteLocation',
	},
},
		created_at: {
	type: 'string',
	format: 'date-time',
},
		updated_at: {
	type: 'string',
	format: 'date-time',
},
	},
} as const;